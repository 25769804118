import { GLOBAL_CONSTANTS } from 'utils/constants'

/**
 * Creates show/hide functionality for provider suffix descriptions
 */

const SELECTORS = {
    COMPONENT: '.js-tooltip'
}

export default class ToolTip {

    constructor(element) {
        this.element = element
        this.toolTipTextbox = document.querySelector(`#${this.element.dataset.toolTipTextboxId}`)
        this.itemIsActive = false
        this.registerEvents()
    }
    registerEvents() {
        this.element.addEventListener('click', this.toggleItem.bind(this))
    }

    /**
     * @param  {} item
     * @desc toggle tool tip textbox open and closed
     */
    toggleItem() {
        this.itemIsActive = !this.itemIsActive

        if (this.itemIsActive) {
            this.toolTipTextbox.classList.remove(GLOBAL_CONSTANTS.CLASSES.HIDDEN)
        } else {
            this.toolTipTextbox.classList.add(GLOBAL_CONSTANTS.CLASSES.HIDDEN)
        }
    }
}

export const ToolTipComponent = {
    'name': 'ToolTip',
    'class': SELECTORS.COMPONENT,
    'Source': ToolTip
}

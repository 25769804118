const CLASSES = {
    COMPONENT: '.js-provider-grid-on-office-page',
    LINK: '.js-providers-grid-expand',
    LINK_MARGIN_TOP: 'provider-grid-on-office-page__expand--margin-top',
    TOGGLE_AREA: '.js-provider-grid-toggle-area',
    OPEN_AREA: '_open'
}
const STRINGS = {
    SEE_FEWER: 'See fewer providers'
}
export default class ProvidersGridExpand {
    constructor(element) {
        this.el = element
        this.wrapper = this.el.querySelector(CLASSES.TOGGLE_AREA)
        this.toggleLink = this.el.querySelector(CLASSES.LINK)
        this.originalLinkText = this.toggleLink.innerText
        this.initialize()
    }

    initialize() {
        this.registerEvets()
    }

    registerEvets() {
        this.toggleLink.addEventListener('click', this.toggleArea.bind(this))
    }

    toggleArea() {
        this.wrapper.classList.toggle(CLASSES.OPEN_AREA)

        if (this.toggleLink.innerText === this.originalLinkText) {
            this.toggleLink.innerText = STRINGS.SEE_FEWER
            this.toggleLink.classList.remove(CLASSES.LINK_MARGIN_TOP)
        } else {
            this.toggleLink.innerText = this.originalLinkText
            this.toggleLink.classList.add(CLASSES.LINK_MARGIN_TOP)
        }
    }
}

export const ProvidersGridExpandComponent = {
    'name': 'Providers Grid Expand',
    'class': CLASSES.COMPONENT,
    'Source': ProvidersGridExpand
}
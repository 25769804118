const mapStyles = [
    {
        featureType: 'poi',
        stylers: [{visibility: 'off'}]
    },
    {
        featureType: 'transit',
        elementType: 'labels.icon',
        stylers: [{visibility: 'off'}]
    },
    {
        featureType: 'administrative.neighborhood',
        stylers: [{visibility: 'off'}]
    },
    {
        featureType: 'road.local',
        stylers: [{visibility: 'off'}]
    },
    {
        featureType: 'landscape',
        elementType: 'geometry.fill',
        stylers: [
            {
                'color': '#f6f6f6'
            }
        ]
    },
    {
        featureType: 'landscape.man_made',
        elementType: 'geometry.fill',
        stylers: [
            {
                'color': '#f6f6f6'
            }
        ]
    },
    {
        featureType: 'landscape.natural',
        elementType: 'geometry.fill',
        stylers: [
            {
                'color': '#f6f6f6'
            }
        ]
    },
    {
        featureType: 'landscape.natural.landcover',
        elementType: 'geometry.fill',
        stylers: [
            {
                'color': '#f6f6f6'
            }
        ]
    },
    {
        featureType: 'landscape.natural.terrain',
        elementType: 'geometry.fill',
        stylers: [
            {
                'color': '#f6f6f6'
            }
        ]
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
            {
                'color': '#fff0d4'
            }
        ]
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
            {
                'color': '#ffdfc3'
            }
        ]
    },
    {
        'featureType': 'road.highway',
        'elementType': 'labels.icon',
        'stylers': [
            {
                'visibility': 'off'
            }
        ]
    },
    {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [
            {
                'color': '#b3e2e6'
            }
        ]
    }
]

export default mapStyles
import { GLOBAL_CONSTANTS } from 'utils/constants'
import Emitter from 'utils/emitter'
import Flickity from 'flickity-fade'

const SELECTORS = {
    COMPONENT: '.js-timeline',
    CAROUSEL: '.js-timeline-carousel',
    NAVIGATION_ANCHOR: '.js-timeline-navigation-anchor',
    MOBILE_CTA: '.js-select-label',
    MOBILE_FORM: '.js-mobile-filter-menu'
}

export default class Timeline {

    constructor(el) {
        this.el = el

        this.onAnchorClick = this.onAnchorClick.bind(this)
        this.onSlideChange = this.onSlideChange.bind(this)
        this.handleModalSubmit = this.handleModalSubmit.bind(this)

        this.mobileFilterCta = document.querySelector(SELECTORS.MOBILE_CTA)

        this.yearMap = new Map()

        this.init()
    }

    init() {
        this.registerEvents()
        this.registerFlickity()
    }

    registerEvents() {
        Emitter.on(GLOBAL_CONSTANTS.EVENTS.FORM_SUBMIT, this.handleModalSubmit)
    }

    registerFlickity() {

        // Create flickity instance
        this.flickity = new Flickity(SELECTORS.CAROUSEL, {
            lazyLoad: true,
            fade: true,
            arrowShape: GLOBAL_CONSTANTS.ARROW_SVG
        })

        // Loop through flickity cells and create year/navigation anchor pairings.
        this.flickity.cells.forEach((cell, i) => {
            const year = cell.element.dataset.year
            if ( !this.yearMap.has(year) ) {
                const anchor = this.el.querySelector(`${SELECTORS.NAVIGATION_ANCHOR}[data-year='${year}']`)
                const input = this.el.querySelector(`${SELECTORS.MOBILE_FORM} input[value='${year}']`)
                anchor.addEventListener('click', this.onAnchorClick)

                const data = {
                    index: i,
                    year,
                    input,
                    anchor
                }

                this.yearMap.set(cell.element.dataset.year, data)
            }
        })

        // Set navigation active slide
        const indexSlide = this.yearMap.get(this.flickity.selectedElement.dataset.year)
        this.setActiveAnchor(indexSlide)

        // Setup change handler
        this.flickity.on('change', this.onSlideChange)

    }

    onSlideChange() {
        const indexSlide = this.yearMap.get(this.flickity.selectedElement.dataset.year)
        this.setActiveAnchor(indexSlide)
    }

    setActiveAnchor({anchor, year}) {
        this.yearMap.forEach(slide => {
            if ( slide.anchor === anchor ) {
                slide.anchor.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
                slide.input.checked = true
            } else {
                slide.anchor.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
                slide.input.checked = false
            }
        })
        this.mobileFilterCta.innerHTML = year
    }

    onAnchorClick(event) {
        event.preventDefault()
        const slideData = this.yearMap.get(event.target.dataset.year)
        this.flickity.select(slideData.index, false, false)
    }

    handleModalSubmit(payload) {
        const slideData = this.yearMap.get(payload.data.year)
        this.flickity.select(slideData.index, false, false)
    }

}


export const TimelineComponent = {
    'name': 'Timeline',
    'class': SELECTORS.COMPONENT,
    'Source': Timeline
}

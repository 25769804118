import { GLOBAL_CONSTANTS } from 'utils/constants'

const CLASSES = {
    COMPONENT: '.js-email-banner-expandable-section',
    EXPAND_LINK: '.js-expand-link',
    EXPAND_LINK_CARET: '.js-expand-link-caret',
    CLOSE_BUTTON: '.close-button',
    EXPANDABLE_SECTION_OPEN: '-expandable-open'
}

export default class ExpandableEmailBannerSection {
    constructor() {
        this.section = document.querySelector(CLASSES.COMPONENT)
        this.closeButton = this.section.querySelector(CLASSES.CLOSE_BUTTON)
        this.expandLink = this.section.querySelector(CLASSES.EXPAND_LINK)
        this.expandLinkCaret = this.section.querySelector(CLASSES.EXPAND_LINK_CARET)
        this.registerEvents()
    }

    registerEvents() {
        this.expandLink.addEventListener('click', this.toggleExpand.bind(this))
        this.closeButton.addEventListener('click', this.toggleExpand.bind(this))
    }

    toggleExpand() {
        this.closeButton.classList.toggle(GLOBAL_CONSTANTS.CLASSES.HIDDEN)
        this.section.classList.toggle(CLASSES.EXPANDABLE_SECTION_OPEN)
    }
}

export const ExpandableEmailBannerSectionComponent = {
    'name': 'ExpandableSection',
    'class': CLASSES.COMPONENT,
    'Source': ExpandableEmailBannerSection
}
const SELECTORS = {
    LIVE_CHAT_BUTTON: '.js-live-chat-button'
}

export default class LiveChatWidget {

    /**
     * @param {HTMLElement} element - button element.
     */
    constructor(element) {
        this.el = element
        this.initialize()
    }

    /**
     * @desc Setup click handler for button.
     */
    initialize() {
        setTimeout(() => {
            this.el.dataset.isLoaded = 'true'
        }, 1200)
        this.el.addEventListener('click', function () {
            // loose focus on button
            document.activeElement.blur()
            this.dataset.isOpen = this.dataset.isOpen === 'true' ? 'false' : 'true'
        })
    }

}

export const LiveChatWidgetComponent = {
    'name': 'LiveChatWidget',
    'class': SELECTORS.LIVE_CHAT_BUTTON,
    'Source': LiveChatWidget
}

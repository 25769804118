import {snakeCase} from 'lodash-es'

const CLASSES = {
    COMPONENT: '.js-gtm-click-tracking'
}

/**
 * This class handles all click-based Google Tag Manager (GTM) event tracking.
 *
 * On load, the class will attach event listeners to the appropriate DOM elements based on the event type,
 * like 'cta_click' or 'internal_nav'.
 *
 * When called, the bound callback function will push the necessary data
 * to the GTM `dataLayer` object attached to the `window`.
 */
export default class GTMClickTracking {

    constructor(element) {
        this.el = element
        this.initialize()
    }

    initialize() {
        this.registerInternalNavigationEventListeners()
        this.registerCtaClickEventListeners()
    }

    /**
     * Register event listeners for qualifying elements to send the
     * `internal_nav` event when clicked.
     *
     * Qualifying elements include:
     *  - anchor tags with 'onemedical.com' in the href attribute
     *  - anchor tags without '-btn-pill' in the class attribute
     */
    registerInternalNavigationEventListeners() {

        document.body.querySelectorAll('a').forEach(link => {
            const pattern = new RegExp('onemedical.com', 'g')
            if (!pattern.test(link.href)) {
                return
            }
            if (link.classList.contains('-btn-pill') ||
                link.classList.contains('-btn-pill--primary') ||
                link.classList.contains('-btn-pill--outline') ||
                link.classList.contains('-btn-pill--melon') ||
                link.classList.contains('-btn-pill--secondary') ||
                link.classList.contains('-btn-pill--tertiary') ||
                link.classList.contains('-btn-pill--quaternary')) {
                return
            }

            link.addEventListener('click', this.handleInternalNavigationClick)
        })

    }

    /**
     * Callback for elements to send the 'internal_nav' event and related fields.
     *
     * @param event {Event} - The click event
     */
    handleInternalNavigationClick(event) {
        const linkClicked = event.target.closest('a')
        const linkDataSet = linkClicked.dataset

        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            'event': 'internal_nav',
            'internal_nav': {
                'link_url': linkClicked.href,
                'nav_element': Object.hasOwn(linkDataSet, 'nav_element') ? linkDataSet.nav_element : 'undefined',
                'nav_subelement': Object.hasOwn(linkDataSet, 'nav_subelement') ? linkDataSet.nav_subelement : 'undefined',
                'nav_location': Object.hasOwn(linkDataSet, 'nav_location') ? linkDataSet.nav_location : 'undefined',
                'event_category': 'navigation',
                'event_label': Object.hasOwn(linkDataSet, 'event_label') ? linkDataSet.event_label : 'undefined'
            }
        })

    }

    /**
     * Register event listeners for qualifying elements to send the
     * `cta_click` event when clicked.
     *
     * Qualifying elements include anchor tags with the class '-btn-pill' or related classes.
     */
    registerCtaClickEventListeners() {

        // nearly all styles of CTAs are listed out because the 'arrow link' style CTA also has '-btn-pill' in the class name,
        // but we don't want to target that one.
        const ctaSelector = 'a.-btn-pill, a.-btn-pill--primary, a.-btn-pill--outline, a.-btn-pill--melon, a.-btn-pill--secondary, a.-btn-pill--tertiary, a.-btn-pill--quaternary, a.-btn-pill--summer'
        document.body.querySelectorAll(ctaSelector).forEach(link => {
            link.addEventListener('click', this.handleCtaClick)
        })

    }

    /**
     * Callback for elements to send the 'cta_click' event and related fields.
     *
     * @param event {Event} - The click event
     */
    handleCtaClick(event) {
        const linkClicked = event.target.closest('a')
        const linkDataSet = linkClicked.dataset

        // ignore 'off-screen' accessibility text
        const linkTextWrapper = linkClicked.querySelector('.cta-base__text-wrapper')
        const rawLinkText = linkTextWrapper ? linkTextWrapper.textContent : linkClicked.textContent
        const linkText = rawLinkText.trim()

        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            'event': 'cta_click',
            'cta_click': {
                'link_name': linkText,
                'link_url': linkClicked.href,
                'is_exit_link': linkClicked.href.includes('onemedical.com') ? 'No' : 'Yes',
                'is_social_media': 'undefined', // to be implemented later
                'link_color': 'undefined', // to be implemented later
                'link_id': linkClicked.id ? linkClicked.id : 'undefined',
                'link_location': 'undefined', // to be implemented later
                'event_category': 'CTA',
                'event_label': Object.hasOwn(linkDataSet, 'event_label') && linkDataSet.event_label ? linkDataSet.event_label : snakeCase(linkText)
            }
        })

    }

}

export const GTMClickTrackingComponent = {
    'name': 'Google Tag Manager click tracking',
    'class': CLASSES.COMPONENT,
    'Source': GTMClickTracking
}

export function serializeForm (form) {
    const retrieved = {}
    if (form) {
        const data = new FormData(form)
        for (const field of data.entries()) {
            retrieved[field[0]] = field[1]
        }
        // FormData doesn't include checkboxes if they are unchecked
        for (const element of form.elements) {
            if (element.type === 'checkbox' && !element.checked) {
                retrieved[element.name] = false
            }
        }
    }

    return retrieved
}
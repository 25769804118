export let cachedWindow = {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth
}

export function updateWindowCache () {
    cachedWindow = {
        innerHeight: window.innerHeight,
        innerWidth: window.innerWidth
    }
}

export function preventScroll () {
    document.body.style.height = `${cachedWindow.innerHeight}px`
    document.documentElement.style.height = `${cachedWindow.innerHeight}px`
    document.documentElement.style.overflow = 'hidden'
}

export function allowScroll (position, options) {
    document.body.removeAttribute('style')
    document.documentElement.removeAttribute('style')
    if (options) {
        window.scrollTo(options)
    } else {
        window.scrollTo(0, position)
    }
}

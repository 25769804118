import React, { Component, Fragment } from 'react'
import { render } from 'react-dom'
import Emitter from 'utils/emitter'
import axios from 'axios'
import { chunk } from 'lodash-es'
import { debounce } from 'throttle-debounce'
import { GLOBAL_CONSTANTS } from 'utils/constants'

import ProvidersPagination from 'components/ProvidersPagination/ProvidersPagination'
import ProvidersFilters from 'components/ProvidersFilters/ProvidersFilters'
import ProviderListItem from 'components/ProviderListItem/ProviderListItem'

const CLASSES = {
    COMPONENT: '.js-provider-grid'
}
class ProvidersList extends Component {

    constructor(props) {
        super(props)
        this.setPaginationIndex = this.setPaginationIndex.bind(this)
        this.onProviderSearchSubmit = this.onProviderSearchSubmit.bind(this)
        this.updateFilterState = this.updateFilterState.bind(this)
        this.setURL = this.setURL.bind(this)
        this.onViewAllClick = this.onViewAllClick.bind(this)
        this.listRef = React.createRef()
        this.debounceFilter = debounce(500, this.filterResults)
        this.setHistoryState = this.setHistoryState.bind(this)
    }

    state = {
        paginationIndex: parseInt((new URL(window.location.href)).searchParams.get('page')) - 1 || 0,
        paginatedResults: [],
        resultsCount: 0,
        filters: this.setFilterState()
    }

    componentDidMount() {
        this.filterResults()
        window.onpopstate = this.setHistoryState
        window.onpushstate = this.setHistoryState
    }

    setFilterState() {
        const PARAM = {
            PEDIATRICS: (new URL(window.location.href)).searchParams.get('is_pediatrics_provider') || 'false',
            ADULTS: (new URL(window.location.href)).searchParams.get('is_adults_provider') || 'false',
            SENIORS: (new URL(window.location.href)).searchParams.get('is_seniors_provider') || 'false',
            FULL_FAMILY: (new URL(window.location.href)).searchParams.get('is_full_family_provider') || 'false',
            ADOLESCENTS_AND_ADULTS: (new URL(window.location.href)).searchParams.get('is_adolescents_and_adults_provider') || 'false',
            VIEW_ALL: (new URL(window.location.href)).searchParams.get('view_all') || 'false'
        }

        return {
            is_pediatrics_provider: PARAM.PEDIATRICS && PARAM.PEDIATRICS === 'true' ? true : false, // eslint-disable-line camelcase
            is_adults_provider: PARAM.ADULTS && PARAM.ADULTS === 'true' ? true : false, // eslint-disable-line camelcase
            is_seniors_provider: PARAM.SENIORS && PARAM.SENIORS === 'true' ? true : false, // eslint-disable-line camelcase
            is_full_family_provider: PARAM.FULL_FAMILY && PARAM.FULL_FAMILY === 'true' ? true : false, // eslint-disable-line camelcase
            is_adolescents_and_adults_provider: PARAM.ADOLESCENTS_AND_ADULTS && PARAM.ADOLESCENTS_AND_ADULTS === 'true' ? true : false, // eslint-disable-line camelcase
            service_area: (new URL(window.location.href)).searchParams.get('service_area_filter') || this.props.serviceArea || GLOBAL_CONSTANTS.DEFAULT_SERVICE_AREA_CODE, // eslint-disable-line camelcase
            provider_search: (new URL(window.location.href)).searchParams.get('provider_search') || '', // eslint-disable-line camelcase
            view_all: PARAM.VIEW_ALL && PARAM.VIEW_ALL === 'true' ? true : false // eslint-disable-line camelcase
        }
    }

    /*
    ** Since we're not using a Javascript router, we need to manually update our component state
    ** on history change.
    */
    setHistoryState() {
        this.state.paginationIndex = parseInt((new URL(window.location.href)).searchParams.get('page')) - 1 || 0
        this.state.filters = this.setFilterState()
        this.filterResults()
    }

    filterResults(callback = null) {

        // filter helper functions
        // return true to pass filter
        const searchFilter = (o) => {
            const pattern = new RegExp(this.state.filters.provider_search.toLowerCase())
            return pattern.test(o.title.toLowerCase())
        }
        const serviceAreaFilter = (o) => {
            return this.state.filters.service_area === GLOBAL_CONSTANTS.DEFAULT_SERVICE_AREA_CODE ? true : o.service_area === this.state.filters.service_area
        }
        const agesSeenFilter = (o) => {
            const filters = this.state.filters
            if (!filters.is_pediatrics_provider &&
                !filters.is_adults_provider &&
                !filters.is_seniors_provider &&
                !filters.is_full_family_provider &&
                !filters.is_adolescents_and_adults_provider) {
                // no filters selected
                return true
            }
            // pass when the age range filter is checked and provider sees that age range
            const isPediatricsProvider = filters.is_pediatrics_provider && o.is_pediatrics_provider
            const isAdultsProvider = filters.is_adults_provider && o.is_adults_provider
            const isSeniorsProvider = filters.is_seniors_provider && o.is_seniors_provider
            const isFullFamilyProvider = filters.is_full_family_provider && o.is_full_family_provider
            const isAdolescentsAndAdultsProvider = filters.is_adolescents_and_adults_provider && o.is_adolescents_and_adults_provider
            return isPediatricsProvider || isAdultsProvider || isSeniorsProvider || isFullFamilyProvider || isAdolescentsAndAdultsProvider
        }

        // apply filters
        const results = this.props.providers.filter((o) => {
            return searchFilter(o) && serviceAreaFilter(o) && agesSeenFilter(o)
        })

        const newState = {
            resultsCount: results.length,
            paginatedResults: this.paginateResults(results)
        }
        this.setState(newState)

        if ( callback ) {
            callback()
        }
    }

    updateFilterState(filter) {
        const filters = Object.assign(this.state.filters, filter)
        this.setState({ filters: filters, paginationIndex: 0 }, () => {
            this.debounceFilter(this.setURL)
        })
    }

    onProviderSearchSubmit(e) {
        e.preventDefault()
    }

    setURL() {
        let newURL =
            window.location.origin
            + window.location.pathname

        for (let [key, value] of Object.entries(this.state.filters)) {
            const isFirst = (Object.keys(this.state.filters)[0] === key)
            const separator = isFirst ? '?' : '&'

            if (key === 'service_area') {
                newURL += separator + `service_area_filter=${value}`
            }
            else if (value !== undefined && value !== '' && key !== 'filters') {
                let parsedValue = (value === 'on') ? true : value
                newURL += separator + `${key}=${parsedValue}`
            }
        }
        if (this.state.paginationIndex !== 0) {
            newURL += `&page=${this.state.paginationIndex + 1}`
        }

        window.history.pushState({ path: newURL }, '', newURL)
    }

    onViewAllClick(e, callback) {
        e.preventDefault()
        this.updateFilterState({view_all: true}) // eslint-disable-line camelcase
        callback()
    }

    setPaginationIndex(e, i) {
        e.preventDefault()
        this.setState({ paginationIndex: i }, () => {
            this.setURL()
        })
    }

    paginateResults(results) {
        return this.state.filters.view_all ? [results] : chunk(results, 32)
    }

    render() {
        this.setURL()

        const {
            selectOptions
        } = this.props

        const {
            paginationIndex,
            paginatedResults,
            resultsCount,
            filters
        } = this.state

        return (
            <Fragment>
                <ProvidersFilters
                    resultsCount={resultsCount}
                    onProviderSearchSubmit={this.onProviderSearchSubmit}
                    updateState={this.updateFilterState}
                    filters={filters}
                    selectOptions={selectOptions}
                />
                {resultsCount === 0 &&
                    <div>Sorry, no providers that match this search.</div>
                }
                {paginatedResults.length > 0 &&
                    <ul className="row row-xs--start" ref={this.listRef}>
                        {paginatedResults[paginationIndex].map((provider, i) => {
                            return (
                                <ProviderListItem
                                    provider={provider}
                                    key={i}
                                />
                            )
                        })}
                    </ul>
                }
                {paginatedResults.length > 1 &&
                    <ProvidersPagination
                        setPaginationIndex={this.setPaginationIndex}
                        paginationIndex={paginationIndex}
                        paginatedResults={paginatedResults}
                        refProp={this.listRef}
                        onViewAllClick={this.onViewAllClick}
                    />
                }
            </Fragment>
        )
    }
}

export default class MountProvidersList {
    constructor(el) {
        this.el = el
        this.fetchProviders()
    }

    fetchProviders(req = '/api/providers/') {
        axios.get(req)
            .then((response) => {
                const data = response.data
                this.renderComponent(data)
            }).catch((error) => {
                Emitter.emit('exception', {
                    error,
                    req
                })
            })
    }

    renderComponent(data) {
        this.component = render(
            <ProvidersList
                providers={data.providers}
                selectOptions={data.select_options}
                serviceArea={data.service_area}
            />,
            this.el
        )
    }
}

export const ProvidersListComponent = {
    'name': 'Provider List',
    'class': CLASSES.COMPONENT,
    'Source': MountProvidersList
}

import { GLOBAL_CONSTANTS } from 'utils/constants'

const CLASSES = {
    COMPONENT: '.js-service-area-preselect',
    SELECTED_AREA: 'input[name="service-area"]:checked',
    CTA: '.js-register-in-area'
}

export default class ServiceAreaPreselectClass {
    constructor(element) {
        this.el = element
        this.cta = this.el.querySelector(CLASSES.CTA)
        this.select = this.el.querySelector(CLASSES.SELECTED_AREA)

        this.initialize()
    }

    /**
     * InitializeEvents
     */
    initialize() {
        this.cta.addEventListener('click', this.forwardToRegWithQuery.bind(this))
    }

    /**
     * Add query param and forward to registration
     */
    forwardToRegWithQuery() {
        let serviceArea = this.el.querySelector(CLASSES.SELECTED_AREA).value
        window.location.href = `${GLOBAL_CONSTANTS.SIGNUP_URL}?service_area_id=${serviceArea}`
    }
}

/**
 * @desc Test component definition used in module-loader
 */

export const ServiceAreaPreselectRegistrationComponent = {
    'name': 'Service area preselect',
    'class': CLASSES.COMPONENT,
    'Source': ServiceAreaPreselectClass
}

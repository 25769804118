// import { jQuery } from 'jquery'

const CLASSES = {
    COMPONENT: '.jetset-lp'
}

export default class JetsetScrolldownClass {

    constructor() {


    }
}


export const JetsetScrolldown = {
    'name': 'Jetset Scrolldown',
    'class': CLASSES.COMPONENT,
    'Source': JetsetScrolldownClass
}
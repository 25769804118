import React, {Component} from 'react'
import classNames from 'classnames'
import withVisibleProp from 'components/withVisibleProp'

class ProvidersListItem extends Component {
    renderProviderListItem(provider, key, visible) {
        return (
            <li className="providers-grid__item col-xs-12 col-md-4 col-lg-3" key={key}>
                <a href={provider.url} className="providers-grid__anchor row row-xs--middle">
                    <div className="providers-grid__image-wrapper col-xs-4 col-md-4 col-lg-3">
                        <div className="providers-grid__item-content">
                            <div
                                className={classNames('providers-grid__item-image', { '-loaded': visible })}
                                style={{ backgroundImage: visible ? `url('${provider.profile_image_url}')` : 'none' }}
                            />
                        </div>
                    </div>
                    <div className="providers-grid__text-wrapper col-xs-8 col-md-4 col-lg-3">
                        <h2 className="providers-grid__item-headline">{provider.display_name}</h2>
                        {provider.office && provider.office.title &&
                            <p className="providers-grid__item-info">
                                {provider.office.title}, {provider.office.state}
                            </p>
                        }
                    </div>
                </a>
            </li>
        )
    }

    render() {
        const {
            provider,
            key,
            visible
        } = this.props

        return this.renderProviderListItem(provider, key, visible)
    }
}

export default withVisibleProp(ProvidersListItem, { threshold: 0, once: true })

import Cookies from 'js-cookie'
/**
 * Functionality to set hidden form fields in an eloqua form with values from
 * the search params or cookies, preferring search params if they are available
 */

const CLASSES = {
    COMPONENT: '.js-eloqua-utm-form'
}

const FIELD_SELECTORS = {
    UTM_SOURCE_FIELD: '[name=utm_source]',
    UTM_MEDIUM_FIELD: '[name=utm_medium]',
    UTM_CONTENT_FIELD: '[name=utm_content]',
    UTM_CAMPAIGN_FIELD: '[name=utm_campaign]',
    UTM_TERM_FIELD: '[name=utm_term]'
}

export default class SetEloquaUtmFormFields {
    /**
     * @desc Set up SetEloquaUtmFormFields elements
     * @param {HTMLElement} element - Element that contains possible sub-navigations
     */

    constructor(element) {
        this.el = element
        this.utmSourceField = this.el.querySelector(FIELD_SELECTORS.UTM_SOURCE_FIELD)
        this.utmMediumField = this.el.querySelector(FIELD_SELECTORS.UTM_MEDIUM_FIELD)
        this.utmContentField = this.el.querySelector(FIELD_SELECTORS.UTM_CONTENT_FIELD)
        this.utmCampaignField = this.el.querySelector(FIELD_SELECTORS.UTM_CAMPAIGN_FIELD)
        this.utmTermField = this.el.querySelector(FIELD_SELECTORS.UTM_TERM_FIELD)
        this.searchParams = new URL(window.location.href).searchParams
        this.initialize()
    }

    /**
     * @desc initialize the class functions after global variables are defined
     */
    initialize() {
        this.setUtmFormFields()
    }

    /**
     * @desc set the hidden utm form fields
     */
    setUtmFormFields() {
        let utmSourceFieldValue,
            utmMediumFieldValue,
            utmContentFieldValue,
            utmCampaignFieldValue,
            utmTermFieldValue

        if (this.searchParams.get('utm_source') !== null) {
            utmSourceFieldValue = this.searchParams.get('utm_source')
            utmMediumFieldValue = this.searchParams.get('utm_medium')
            utmContentFieldValue = this.searchParams.get('utm_content')
            utmCampaignFieldValue = this.searchParams.get('utm_campaign')
            utmTermFieldValue = this.searchParams.get('utm_term')
        } else {
            utmSourceFieldValue = Cookies.get('utm_source')
            utmMediumFieldValue = Cookies.get('utm_medium')
            utmContentFieldValue = Cookies.get('utm_content')
            utmCampaignFieldValue = Cookies.get('utm_campaign')
            utmTermFieldValue = Cookies.get('utm_term')
        }

        if (utmSourceFieldValue) {
            this.utmSourceField.value = utmSourceFieldValue
        }

        if (utmMediumFieldValue) {
            this.utmMediumField.value = utmMediumFieldValue
        }

        if (utmContentFieldValue) {
            this.utmMediumField.value = utmContentFieldValue
        }

        if (utmCampaignFieldValue) {
            this.utmCampaignField.value = utmCampaignFieldValue
        }

        if (utmTermFieldValue) {
            this.utmTermField.value = utmTermFieldValue
        }
    }
}

/**
 * @desc SetEloquaUtmFormFields component definition used in module loader
 */
export const SetEloquaUtmFormFieldsComponent = {
    'name': 'SetEloquaUtmFormFields',
    'class': CLASSES.COMPONENT,
    'Source': SetEloquaUtmFormFields
}

import GoogleMapsLoader from 'google-maps'
GoogleMapsLoader.LIBRARIES = ['geometry']
GoogleMapsLoader.KEY = GOOGLE_API_KEY
GoogleMapsLoader.VERSION = MAP_API_VERSION

export const mapLoader = GoogleMapsLoader

export function isMobile () {
    return (window.innerWidth <= GLOBAL_CONSTANTS.BREAKPOINTS.MOBILE_LG)
}

export function isStandardTablet () {
    return (window.innerWidth < GLOBAL_CONSTANTS.BREAKPOINTS.TABLET)
}

export function isTablet () {
    return (window.innerWidth < GLOBAL_CONSTANTS.BREAKPOINTS.TABLET_LG)
}

export function isDesktop () {
    return (window.innerWidth >= GLOBAL_CONSTANTS.BREAKPOINTS.DESKTOP)
}

export function navHeight () {
    return (document.querySelector('.js-navigation').getBoundingClientRect().height)
}

const TIMING = {
    FAST_ANIM_TIME: 250, //this should be kept in parity with the CSS $anim_duration_fast variable
    STD_ANIM_TIME: 600, //this should be kept in parity with the CSS $anim_duration variable
    NAV_SCROLL_THROTTLE: 10,
    STANDARD_THROTTLE: 150,
    RESIZE_THROTTLE: 100
}

const MAP_ICONS = {
    OFFICE: `${PUBLIC_PATH}svg/pin_icon.svg`,
    SENIORS_OFFICE: `${PUBLIC_PATH}svg/seniors_pin_icon.svg`,
    OFFICE_ACTIVE: `${PUBLIC_PATH}svg/pin_icon_active.svg`,
    USER: `${PUBLIC_PATH}svg/single_location_icon.svg`
}

const MAP_LEGEND = `${PUBLIC_PATH}images/office-map-legend.png`

// role 43 - "Pediatrics", 20min appointments only for kids at the time of writing
const APPOINTMENTS = {
    PEDIATRIC_ROLE: 43,
    PEDIATRIC_APPOINTMENT_LENGTH: 20,
    PEDIATRIC_LABEL: 'for kids only'
}

export const GLOBAL_CONSTANTS = {
    DEFAULT_SERVICE_AREA_CODE: 'all',
    SIGNUP_URL: 'https://members.app.onemedical.com/pt/registration/register',
    ARROW_SVG: 'M72.2,100l5.6-5.6L33.3,50L77.8,5.6L72.2,0l-50,50L72.2,100z',
    CAROUSEL_ARROW_CIRCLE: 'M2,50A48,48,0,1,1,50,98,48,48,0,0,1,2,50Zm1.9,0A46.1,46.1,0,1,0,50,3.9,46.2,46.2,0,0,0,3.9,50Zm46,22.6a.7.7,0,0,0,.6-.3.9.9,0,0,0,.1-1.3L32,51.7H73.2a.9.9,0,0,0,.9-1,.9.9,0,0,0-.9-.9H32L50.6,30.5a.9.9,0,0,0-.1-1.3.9.9,0,0,0-1.3,0l-20,20.9-.2.2h0a.4.4,0,0,0-.1.3c0,.2,0,.3.1.4h0l.2.2,20,20.9A.9.9,0,0,0,49.9,72.6Z',
    CAROUSEL_ARROW: 'M49.9,72.6a.7.7,0,0,0,.6-.3.9.9,0,0,0,.1-1.3L32,51.7H73.2a.9.9,0,0,0,.9-1,.9.9,0,0,0-.9-.9H32L50.6,30.5a.9.9,0,0,0-.1-1.3.9.9,0,0,0-1.3,0l-20,20.9-.2.2h0a.4.4,0,0,0-.1.3c0,.2,0,.3.1.4h0l.2.2,20,20.9A.9.9,0,0,0,49.9,72.6Z',
    EVENTS: {
        SCROLL: 'scroll',
        RESIZE: 'resize',
        ORIENTATION_CHANGE: 'orientation-change',
        FOCUS: 'focus',
        BLUR: 'blur',
        CHANGE: 'change',
        KEYDOWN: 'keydown',
        LAZY_LOADED: 'lazyloaded',
        FORM_SUBMIT: 'form-submit',
        CLOSE_MOBILE_MENU: 'close-mobile-menu'
    },
    CLASSES: {
        ACTIVE: '-active',
        LOADED: '-loaded',
        FIXED: '-fixed',
        HOVER: '-hover',
        INACTIVE: '-inactive',
        STATIC: '-static',
        HIDDEN: '-hidden',
        VISIBLE: '-visible',
        TRANSPARENT: '-transparent',
        NO_SCROLL: '-no-scroll'
    },
    BREAKPOINTS: {
        MOBILE: 320,
        MOBILE_LG: 375,
        TABLET: 768,
        TABLET_LG: 1024,
        DESKTOP: 1280,
        DESKTOP_LG: 1440
    },
    KEYS: {
        ESC: 27
    },
    TIMING,
    MAP_ICONS,
    MAP_LEGEND,
    APPOINTMENTS
}

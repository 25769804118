import React, {Component, Fragment} from 'react'
import FilterGroup from 'base/components/FilterGroup'

export default class ProvidersFilters extends Component {

    constructor(props) {
        super(props)
        this.applySelect = this.applySelect.bind(this)
        this.parseTypeResult = this.parseTypeResult.bind(this)
    }

    state = {
        selects: this.generateSelectModel(this.props.selectOptions)
    }

    generateSelectModel(options) {
        const parsedOptions = {}
        options.forEach(option => {
            parsedOptions[option.code] = {
                label: option.name
            }
        })

        const selects = {
            service_area: { // eslint-disable-line camelcase
                name: 'Locations',
                type: 'select',
                id: 'service_area',
                selectedOption: this.props.filters['service_area'],
                options: {
                    all: {
                        label: 'All Locations'
                    },
                    ...parsedOptions
                }
            },
            filters: { // eslint-disable-line camelcase
                name: 'Ages Seen',
                type: 'checkboxes',
                checkboxes: {
                    is_full_family_provider: { // eslint-disable-line camelcase
                        label: 'Sees all ages',
                        id: 'is_full_family_provider',
                        selected: this.props.filters['is_full_family_provider'],
                        description: 'Primary care providers who see both kids and adults'
                    },
                    is_seniors_provider: { // eslint-disable-line camelcase
                        label: 'Sees ages 65+',
                        id: 'is_seniors_provider',
                        selected: this.props.filters['is_seniors_provider'],
                        description: 'Primary care providers who see ages 65+ on Medicare'
                    },
                    is_adults_provider: { // eslint-disable-line camelcase
                        label: 'Sees ages 18+',
                        id: 'is_adults_provider',
                        selected: this.props.filters['is_adults_provider'],
                        description: 'Primary care providers who only see adults'
                    },
                    is_adolescents_and_adults_provider: { // eslint-disable-line camelcase
                        label: 'Sees ages 14+',
                        id: 'is_adolescents_and_adults_provider',
                        selected: this.props.filters['is_adolescents_and_adults_provider'],
                        description: 'Primary care providers who see adolescents and adults'
                    },
                    is_pediatrics_provider: { // eslint-disable-line camelcase
                        label: 'Sees kids under 18',
                        id: 'is_pediatrics_provider',
                        selected: this.props.filters['is_pediatrics_provider'],
                        description: 'Pediatricians who see babies, teens and in-betweens'
                    }
                }

            }
        }

        return selects
    }

    /**
     * Callback function passed as prop to <FilterGroup />
     *
     * @param data map of form checkbox names and values
     */
    applySelect(data) {
        let parsedData = {...data}
        const state = Object.assign({}, this.state.selects)

        Object.keys(this.state.selects).forEach(key => {
            if (this.state.selects[key].type === 'checkboxes') {
                Object.keys(this.state.selects[key].checkboxes).forEach((checkboxKey) => {
                    parsedData[checkboxKey] = data[checkboxKey]
                    state[key].checkboxes[checkboxKey].selected = data[checkboxKey]
                })
            } else {
                state[key].selectedOption = data[key] || state[key].selectedOption
            }
        })

        this.setState({selects: state})
        this.props.updateState(parsedData)
    }

    parseTypeResult() {

        const labelList = []
        Object.keys(this.state.selects).forEach((key) => {
            const select = this.state.selects[key]
            if (select.type === 'select' ) {
                labelList.push(select.options[select.selectedOption].label)
            } else {
                Object.keys(this.state.selects[key].checkboxes).forEach((checkboxKey) => {
                    const checkbox = this.state.selects[key].checkboxes[checkboxKey]
                    if (checkbox.selected) { labelList.push(checkbox.label) }
                })
            }
        })

        return labelList.join(', ')
    }

    render() {

        const {
            onProviderSearchSubmit,
            updateState,
            filters
        } = this.props

        const {
            selects
        } = this.state

        return (
            <Fragment>
                <div className="providers-grid__filters-wrapper">
                    <div className="row row-xs--between row-lg--middle">
                        <div className="col-xs-12 col-md-4">
                            <form onSubmit={e => onProviderSearchSubmit(e)}>
                                <div className="providers-grid__input-wrapper">
                                    <input
                                        type="text"
                                        className="providers-grid__input"
                                        name="provider_search"
                                        value={filters.provider_search}
                                        placeholder="Search for a provider name"
                                        aria-label="Search for a provider name"
                                        onChange={e => updateState({[e.target.name]: e.target.value})}
                                    />
                                    <button className="providers-grid__btn" aria-label="Submit">
                                        <svg className="providers-grid__icon" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                            <g stroke="none" strokeWidth="1" fillRule="evenodd">
                                                <g transform="translate(-321.000000, -24.000000)" fillRule="nonzero">
                                                    <g transform="translate(321.000000, 24.000000)">
                                                        <path d="M6.5,11 C8.98528137,11 11,8.98528137 11,6.5 C11,4.01471863 8.98528137,2 6.5,2 C4.01471863,2 2,4.01471863 2,6.5 C2,8.98528137 4.01471863,11 6.5,11 Z M11.7489008,10.3346872 L15.5961941,14.1819805 L14.1819805,15.5961941 L10.3346872,11.7489008 C9.2597335,12.5355664 7.9341039,13 6.5,13 C2.91014913,13 0,10.0898509 0,6.5 C0,2.91014913 2.91014913,0 6.5,0 C10.0898509,0 13,2.91014913 13,6.5 C13,7.9341039 12.5355664,9.2597335 11.7489008,10.3346872 Z" />
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="providers-grid__filters col-xs-12 col-md-8">
                            <FilterGroup
                                alignRight
                                headline="Filter Providers"
                                selects={selects}
                                applySelect={this.applySelect}
                                filters={filters}
                            />
                        </div>
                    </div>
                </div>
                <div className="providers-grid__type col-xs-12 col-md--hide">
                    Viewing: <span className="providers-grid__type-selection">{this.parseTypeResult(filters)}</span>
                </div>
                <div className="providers-grid__count">&nbsp;</div>
            </Fragment>
        )
    }
}

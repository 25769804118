import { GLOBAL_CONSTANTS } from 'utils/constants'
import { throttle, debounce } from 'throttle-debounce'
import Emitter from 'utils/emitter'
import { serializeForm } from 'utils/serializeForm'

/*
** Filters the list of plans and carriers on the Insurance Finder page
** by matching the selected value to the carrier data-location attribute
*/

const STRINGS = {
    ALL_LOCATIONS: 'all-locations',
    ALL_CARRIERS: 'all-carriers',
    LOCATIONS_PARAM: 'location',
    CARRIER_PARAM: 'carrier',
    SEARCH_PARAM: 'search'
}

const CLASSES = {
    COMPONENT: '.js-insurance-filter',
    SEARCH_FORM: '.js-insurance-search-form',
    SEARCH_INPUT: '.js-insurance-search-input',
    INSURANCE_LOCATION: '.js-insurance-location',
    INSURANCE_CARRIER: '.js-insurance-carrer',
    INSURANCE_PLAN: '.js-insurance-plan',
    RESULTS_LIST: '.js-results-list',
    RESULTS_COUNT: '.js-results-count',
    MESSAGE_WRAPPER: '.js-message-wrapper',
    CURRENTLY_VIEWING: '.js-insurance-currently-viewing',
    CTA_BAR: '.js-insurance-cta-bar'
}

export default class InsuranceFinderClass {
    /**
     * @desc Set up Insurance Plan Filter elements
     *
     */

    constructor(component) {
        this.component = component
        this.footer = document.querySelector('footer')

        this.handleDropdownChange = this.handleDropdownChange.bind(this)
        this.onSearchFormSubmit = this.onSearchFormSubmit.bind(this)
        this.debounceSearch = debounce(500, this.onSearchFormKeypress.bind(this))
        this.updateVisibleItems = this.updateVisibleItems.bind(this)
        this.filterSetMethod = this.filterSetMethod.bind(this)

        this.searchForm = document.querySelector(CLASSES.SEARCH_FORM)
        this.searchInput = document.querySelector(CLASSES.SEARCH_INPUT)
        this.insuranceLocations = Array.from(document.querySelectorAll(CLASSES.INSURANCE_LOCATION))
        this.resultsDiv = document.querySelector(CLASSES.RESULTS_COUNT)
        this.messageWrapper = document.querySelector(CLASSES.MESSAGE_WRAPPER)
        this.currentlyViewingEl = document.querySelector(CLASSES.CURRENTLY_VIEWING)
        this.ctaBar = document.querySelector(CLASSES.CTA_BAR)
        this.resultsList = document.querySelector(CLASSES.RESULTS_LIST)

        this.urlState = new URL(window.location)

        this.filters = new Proxy({
            location: this.urlState.searchParams.get(STRINGS.LOCATIONS_PARAM) || STRINGS.ALL_LOCATIONS,
            carrier: this.urlState.searchParams.get(STRINGS.CARRIER_PARAM) || STRINGS.ALL_CARRIERS,
            search: this.urlState.searchParams.get(STRINGS.SEARCH_PARAM) || ''
        }, {
            set: this.filterSetMethod
        })

        this.initialize()

    }

    initialize() {
        this.registerEvents()
        this.parseInsuranceList(this.updateVisibleItems)
        this.component.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
    }

    registerEvents() {
        this.searchForm.addEventListener('submit', this.onSearchFormSubmit)
        this.searchInput.addEventListener('keydown', this.debounceSearch)
        Emitter.on(GLOBAL_CONSTANTS.EVENTS.FORM_SUBMIT, this.handleDropdownChange)

        this.throttleScroll = throttle(GLOBAL_CONSTANTS.TIMING.STANDARD_THROTTLE, this.handleScroll.bind(this))
        Emitter.on(GLOBAL_CONSTANTS.EVENTS.SCROLL, this.throttleScroll)
    }

    /*
    ** The set method for our proxy on this.filters
    */
    filterSetMethod(obj, prop, value) {
        obj[prop] = value
        this.updateQueryString()
        this.updateVisibleItems()
        return true
    }

    /*
    ** Parse the DOM structure and generate our node/data relationship
    {
        [location]: {
            carriers: {
                [carrier]: {
                    id: [carrier]
                    node: DOM node
                    plans: [
                        {
                            id: [plan]
                            node: DOM node
                            searchString: [carrier][plan]
                        }
                    ]
                }
            }
        }
    }
    */
    parseInsuranceList(callback) {

        this.locationMap = {}
        this.locationLabels = {[STRINGS.ALL_LOCATIONS]: 'All Locations'}
        this.carrierLabels = {[STRINGS.ALL_CARRIERS]: 'All Carriers'}

        this.insuranceLocations.forEach(node => {
            const carriers = Array.from(node.querySelectorAll(CLASSES.INSURANCE_CARRIER))
            const carrierMap = {}

            carriers.forEach(carrierNode => {
                const plans = Array.from(carrierNode.querySelectorAll(CLASSES.INSURANCE_PLAN))

                const planMap = plans.map(planNode => {
                    return {
                        id: planNode.dataset.id,
                        searchString: `${carrierNode.dataset.id.replace(/-/g, ' ')} ${planNode.dataset.id.replace(/-/g, ' ')}`,
                        node: planNode
                    }
                })

                carrierMap[carrierNode.dataset.id] = {
                    id: carrierNode.dataset.id,
                    node: carrierNode,
                    plans: planMap
                }
                this.carrierLabels[carrierNode.dataset.id] = carrierNode.dataset.label
            })

            const locationData = {
                id: node.dataset.id,
                carriers: carrierMap,
                node: node
            }

            this.locationMap[node.dataset.id] = locationData
            this.locationLabels[node.dataset.id] = node.dataset.label

        })

        if (callback) { callback() }

    }

    /*
    ** Search form submission callback
    */
    onSearchFormSubmit(event) {
        event.preventDefault()
        const data = serializeForm(event.target)
        this.filters = Object.assign(this.filters, data)
    }

    /*
    ** Search form input keypress callback
    */
    onSearchFormKeypress() {
        const data = serializeForm(this.searchForm)
        this.filters = Object.assign(this.filters, data)
    }

    /*
    ** Callback for our emitter subscription
    */
    handleDropdownChange(payload) {
        this.filters = Object.assign(this.filters, payload.data)
    }

    /*
    ** Scroll event callback for sticky cta
    */
    handleScroll() {

        const clientRec = this.component.getBoundingClientRect()
        const resultsListHeight = this.resultsList.clientHeight
        const footerClientRec = this.footer.getBoundingClientRect()

        // Check if insurance content height is less then window height. Trigger cta bar visibility
        // at top of page instead of when the insruance container hits the top of the screen.
        if ( resultsListHeight < window.innerHeight ) {
            if ( footerClientRec.top > window.innerHeight ) {
                this.addCtaActiveClass()
            } else {
                this.removeCtaActiveClass()
            }
        } else {
            if ( clientRec.top < 0 && footerClientRec.top > window.innerHeight) {
                this.addCtaActiveClass()
            } else {
                this.removeCtaActiveClass()
            }
        }

    }

    /*
    ** Updates the filter params in the URL
    */
    updateQueryString() {
        const url = new URL(document.location)
        Object.keys(this.filters).forEach((key) => {
            url.searchParams.set(key, this.filters[key])
        })
        window.history.pushState(null, '', url.toString())
    }

    updateVisibleItems() {

        // Our general bucket to track active plans. Used to display our final count in the UI
        let activePlanCountByCarrier = []
        const pattern = new RegExp(this.filters.search.toLowerCase())

        Object.keys(this.locationMap).forEach(key => {

            const location = this.locationMap[key]

            // Loop through locations
            if ( this.filters.location === STRINGS.ALL_LOCATIONS || this.filters.location === location.id) {
                this.enableItem(location.node)

                // Loop through carriers
                let activeCarrierCount = 0

                Object.keys(location.carriers).forEach(key => {
                    const carrier = location.carriers[key]
                    let activePlanCount = 0

                    if ( this.filters.carrier === STRINGS.ALL_CARRIERS || this.filters.carrier === carrier.id) {
                        this.enableItem(carrier.node)

                        // Loop through plans
                        Object.keys(carrier.plans).forEach(key => {
                            const plan = carrier.plans[key]

                            // Test our plan search string against our search param
                            const match = pattern.test(plan.searchString)

                            if ( match ) {
                                // If match increment the active counts for plans and this particular carrier
                                this.enableItem(plan.node)
                                activePlanCount++
                                activeCarrierCount++
                            } else {
                                this.disableItem(plan.node)
                            }
                        })

                    } else {
                        this.disableItem(carrier.node)
                    }

                    // If there aren't any filered matches for a plan, hide the carrier element
                    activePlanCountByCarrier.push(activePlanCount)
                    if ( activePlanCount < 1 ) {
                        this.disableItem(carrier.node)
                    }

                })

                // If there aren't any filered matches for a carrier, hide the location element
                if ( activeCarrierCount < 1 ) {
                    this.disableItem(location.node)
                }

            } else {
                this.disableItem(location.node)
            }

        })

        // Reduce our array of plans per carrier to final count
        const reducer = (accumulator, currentValue) => accumulator + currentValue
        const activePlanCount = activePlanCountByCarrier.reduce(reducer)
        this.resultsDiv.innerHTML = activePlanCount

        // Show our no results messaging
        if ( activePlanCount < 1 ) {
            this.messageWrapper.classList.add(GLOBAL_CONSTANTS.CLASSES.VISIBLE)
        } else if ( activePlanCount > 0 && this.messageWrapper.classList.contains(GLOBAL_CONSTANTS.CLASSES.VISIBLE) ) {
            this.messageWrapper.classList.remove(GLOBAL_CONSTANTS.CLASSES.VISIBLE)
        }

        // Set our currently viewing notification
        const locationLabel = this.locationLabels[this.filters.location]
        const carrierLabel = this.carrierLabels[this.filters.carrier]
        this.currentlyViewingEl.innerHTML = `${locationLabel}, ${carrierLabel}`

    }

    /*
    ** General helper for disabling items
    */
    disableItem(item) {
        item.classList.add(GLOBAL_CONSTANTS.CLASSES.INACTIVE)
    }

    /*
    ** General helper for showing items
    */
    enableItem(item) {
        item.classList.remove(GLOBAL_CONSTANTS.CLASSES.INACTIVE)
    }

    /*
    ** General helper for adding active class to sticky cta
    */
    addCtaActiveClass() {
        if (this.ctaBar && !this.ctaBar.classList.contains(GLOBAL_CONSTANTS.CLASSES.ACTIVE)) {
            this.ctaBar.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        }
    }

    /*
    ** General helper for removing active class from sticky cta
    */
    removeCtaActiveClass() {
        if (this.ctaBar && this.ctaBar.classList.contains(GLOBAL_CONSTANTS.CLASSES.ACTIVE)) {
            this.ctaBar.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        }
    }

}

export const InsuranceFinderComponent = {
    'name': 'InsuranceFinder',
    'class': CLASSES.COMPONENT,
    'Source': InsuranceFinderClass
}


import Cookies from 'js-cookie'

const SELECTORS = {
    DOWNLOAD_LINK: '.media-resources-block__link a'
}

export const MEDIA_DOWNLOAD_COOKIE_NAME = 'has_completed_media_resource_download_form'

export default class MediaResourceDownloadLink {
    /**
     * @desc Setup click handler for link.
     * @param {HTMLElement} el - Element that is the link.
     *
     */
    constructor(element) {
        this.el = element
        this.clickedDownloadLink = this.clickedDownloadLink.bind(this)
        this.initialize()
    }

    /**
     * @desc initialize the class functions after global variables are defined
     */
    initialize() {
        this.registerEvents()
    }

    /**
     * @desc Listen for the global scroll event.
     */
    registerEvents () {
        this.el.addEventListener('click', this.clickedDownloadLink)
    }

    /**
     * @desc When clicked, show modal if necessary.
     * @param ev The click event.
     */
    clickedDownloadLink(ev) {
        const hasAlreadyCompletedForm = Cookies.get(MEDIA_DOWNLOAD_COOKIE_NAME)

        if (hasAlreadyCompletedForm) {
            return
        }

        // Don't block clicks with alt/meta keys pressed.
        if (ev.shiftKey || ev.ctrlKey || ev.metaKey) {
            return
        }

        ev.preventDefault()
        window.location.href = '/download-media-resource'
    }

    /**
     * @desc Tear down the event listeners
     */
    tearDown () {
        this.el.removeEventListener('click', this.clickedDownloadLink)
    }
}

/**
 * @desc Test component definition used in module-loader
 */

export const MediaResourceDownloadLinkComponent = {
    'name': 'MediaResourceDownloadLink',
    'class': SELECTORS.DOWNLOAD_LINK,
    'Source': MediaResourceDownloadLink
}
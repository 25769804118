import { GLOBAL_CONSTANTS } from 'utils/constants'
import Emitter from 'utils/emitter'

/**
 * Filters the list of jobs on the Careers page
 * by matching the selected value to the jobs data-location attribute
 */

const STRINGS = {
    ALL_LOCATIONS: 'all-locations',
    ALL_DEPARTMENTS: 'all-departments',
    LOCATIONS_PARAM: 'location',
    DEPARTMENT_PARAM: 'department'
}

const CLASSES = {
    COMPONENT: '.js-careers-filter',
    FILTER_WRAPPER: '.js-filter-wrapper',
    LOCATIONS_DROPDOWN: '.js-filter-locations-dropdown',
    DEPARTMENTS_DROPDOWN: '.js-filter-departments-dropdown',
    FILTER_ITEM: '.js-filter-item',
    DEPARTMENT_WRAPPER: '.js-department-wrapper',
    MESSAGE_WRAPPER: '.js-message-wrapper'
}

export default class CareersFilter {
    /**
     * @desc Set up CareersFilter elements
     *
     */

    constructor(el) {
        this.paramTypeExclusion = el.getAttribute('data-type')

        this.handleDropdownChange = this.handleDropdownChange.bind(this)

        this.filterWrapper = document.querySelector(CLASSES.FILTER_WRAPPER)
        this.messageWrapper = document.querySelector(CLASSES.MESSAGE_WRAPPER)
        this.urlLocation = (new URL(window.location.href)).searchParams.get(STRINGS.LOCATIONS_PARAM)
        this.urlDepartment = (new URL(window.location.href)).searchParams.get(STRINGS.DEPARTMENT_PARAM)
        this.jobDepartments = Array.from(document.querySelectorAll(CLASSES.DEPARTMENT_WRAPPER))

        this.filters = {
            location: this.urlLocation ? this.urlLocation : STRINGS.ALL_LOCATIONS,
            department: this.urlDepartment ? this.urlDepartment : STRINGS.ALL_DEPARTMENTS
        }

        const itemElements = Array.from(document.querySelectorAll(CLASSES.FILTER_ITEM))
        this.items = itemElements.map((element) => {
            const location = element.dataset.location
            const department = element.dataset.department

            return {
                location,
                department,
                element
            }
        })

        this.initialize()
    }

    /**
     * @desc initialize the class functions after global variables are defined
     */
    initialize() {
        this.registerEvents()
        this.updateVisibleItems()
    }

    /**
     * @desc Set out initial dropdown selection
     */
    setInitialDropdownValue() {
        if (this.filters.location.indexOf(',') > -1) {
            // This is a hack for the media compaign so that we can send out urls to show all jobs in the bay area
            // http://...careers/all-departments/?location=san-francisco-ca,oakland-ca,...
            // without this the component breaks
            this.locationsDropdown.value = STRINGS.ALL_LOCATIONS
        } else {
            this.locationsDropdown.value = this.filters.location
        }

        this.departmentsDropdown.value = this.filters.department
    }


    /**
     * @desc Listen for the change event on the select dropdown
     */
    registerEvents() {
        Emitter.on(GLOBAL_CONSTANTS.EVENTS.FORM_SUBMIT, this.handleDropdownChange)
    }

    /**
     * @desc Handles dropdown change by firing our updateVisibleItems function
     */
    handleDropdownChange(payload) {
        this.filters = Object.assign(this.filters, payload.data)
        this.updateVisibleItems(this.updateQueryString.bind(this))
    }

    updateVisibleItems(callback) {
        const inactiveList = []
        let listOfFilters = this.filters.location.split(',')
        this.items.forEach((item) => {
            if (
                (listOfFilters.indexOf(item.location) > -1 || this.filters.location === STRINGS.ALL_LOCATIONS)
                    && (this.filters.department === item.department || this.filters.department === STRINGS.ALL_DEPARTMENTS)
            ) {
                this.enableItem(item)
                this.activateItem(item)
            } else {
                this.disableItem(item)
            }
        })

        this.jobDepartments.forEach(item => {
            const activeJobsInDepartment = Array.from(item.querySelectorAll(`.${GLOBAL_CONSTANTS.CLASSES.ACTIVE}`))

            if (activeJobsInDepartment.length === 0) {
                item.classList.add(GLOBAL_CONSTANTS.CLASSES.INACTIVE)
                inactiveList.push(item)
            } else {
                item.classList.remove(GLOBAL_CONSTANTS.CLASSES.INACTIVE)
            }
        })

        if ( this.messageWrapper ) {
            if (inactiveList.length === this.jobDepartments.length) {
                this.messageWrapper.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
            } else {
                this.messageWrapper.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
            }
        }

        if ( callback ) {
            callback()
        }

    }

    updateQueryString() {
        const url = new URL(document.location)
        Object.keys(this.filters).forEach((key) => {
            if (key !== this.paramTypeExclusion) {
                url.searchParams.set(key, this.filters[key])
            }
        })
        window.history.pushState(null, '', url.toString())
    }

    disableItem(item) {
        item.element.classList.add(GLOBAL_CONSTANTS.CLASSES.INACTIVE)
        item.element.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        item.element.setAttribute('aria-hidden', 'true')
        item.element.setAttribute('tabindex', '-1')
    }

    enableItem(item) {
        item.element.classList.remove(GLOBAL_CONSTANTS.CLASSES.INACTIVE)
        item.element.setAttribute('aria-hidden', 'false')
        item.element.removeAttribute('tabindex')
    }

    activateItem(item) {
        item.element.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
    }
}

/**
 * @desc CareersFilter component definition used in module-loader
 */

export const CareersFilterComponent = {
    'name': 'CareersFilter',
    'class': CLASSES.COMPONENT,
    'Source': CareersFilter
}
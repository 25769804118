import { GLOBAL_CONSTANTS } from 'utils/constants'
/**
 * Functionality to toggle the revealing of the Greenhouse iFrame application form on the
 * Careers Detail page
 */

const CLASSES = {
    COMPONENT: '.js-greenhouse-iframe',
    GREENHOUSE_WRAPPER: '.js-greenhouse-iframe-wrapper',
    APPLY_BUTTON: '.js-apply-button'
}

export default class GreenhouseiFrame {
    /**
     * @desc Set up GreenhouseiFrame elements
     *
     */

    constructor() {
        this.greenhouseWrapper = document.querySelector(CLASSES.GREENHOUSE_WRAPPER)
        this.applyButton = document.querySelector(CLASSES.APPLY_BUTTON)
        this.searchParams = new URL(window.location.href).searchParams
        this.initialize()
    }

    /**
     * @desc initialize the class functions after global variables are defined
     */
    initialize() {
        this.loadIFrame()
        this.registerEvents()
    }

    loadIFrame() {
        let jobToken = this.greenhouseWrapper.dataset.jobId
        // This ghSrc is used to track if the application is coming from linkedin or any other source
        let ghSrcParam = this.searchParams.get('gh_src') ? `&t=${this.searchParams.get('gh_src')}` : ''
        let IFrameHTMLSrc = `https://boards.greenhouse.io/embed/job_app?for=onemedical&token=${jobToken}${ghSrcParam}`
        let IFrameHTML = `
            <iframe
                id="grnhse_iframe"
                width="100%"
                frameborder="0"
                scrolling="yes"
                allow="geolocation"
                title="Greenhouse Job Board"
                src="${IFrameHTMLSrc}"
                height="1411">
            </iframe>`
        this.greenhouseWrapper.insertAdjacentHTML('afterbegin', IFrameHTML)
    }

    /**
     * @desc Listen for click event on the Apply button
     */
    registerEvents () {
        this.applyButton.addEventListener('click', this.handleClick.bind(this))
    }

    /**
     * @desc Handles click by toggling reveal class
     */
    handleClick() {
        this.greenhouseWrapper.classList.toggle(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
    }
}

/**
 * @desc GreenhouseiFrame component definition used in module-loader
 */

export const GreenhouseiFrameComponent = {
    'name': 'GreenhouseiFrame',
    'class': CLASSES.COMPONENT,
    'Source': GreenhouseiFrame
}

import { isMobile, isTablet } from 'utils/constants'

const CLASSES = {
    COMPONENT: '.gifting-snow'
}

export default class FallingSnowClass {
    constructor() {
        this.container = document.querySelector(CLASSES.COMPONENT)
        if (this.container) {
            window.addEventListener('load', this.createSnow.bind(this))
        }
    }

    createSnow() {
        this.flakes = document.createElement('div')
        this.flakes.id = 'snowflakes'
        this.container.appendChild(this.flakes)

        let allFlakes = []
        let numFlakes
        if (isMobile()) {
            numFlakes = 70
        } else if (isTablet()) {
            numFlakes = 120
        } else {
            numFlakes = 200
        }
        for (let i = 0; i < numFlakes; i++) {
            allFlakes[i] = '<span></span>'
        }
        this.flakes.innerHTML = allFlakes.join('')
    }
}

export const FallingSnow = {
    'name': 'Falling Snow',
    'class': CLASSES.COMPONENT,
    'Source': FallingSnowClass
}

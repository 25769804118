import { GLOBAL_CONSTANTS } from 'utils/constants'
import { serializeForm } from 'utils/serializeForm'
import Emitter from 'utils/emitter'
import whatInput from 'what-input'

const SELECTORS = {
    COMPONENT: '.js-filter-group',
    SELECTINSTANCE: '.js-form-select',
    FORM: '.js-form-select-form',
    SELECTBUTTON: '.js-form-select-button',
    INLINESUBMITBUTTON: '.js-form-inline-submit',
    MOBILEMENU: '.js-mobile-filter-menu',
    MOBILEFORM: '.js-form-select-mobile-form',
    MOBILECTA: '.js-mobile-filter-cta',
    MOBILECLOSEBUTTON: '.js-menu-close',
    CANCELBUTTON: '.js-form-select-cancel',
    SELECTLABEL: '.js-select-label',
    HIDDENINPUT: '.js-select-menu-hidden-input'
}

export default class FormSelect {

    constructor(el) {
        this.instance = el
        this.type = el.dataset.type

        this.mobileMenu = this.instance.querySelector(SELECTORS.MOBILEMENU)
        this.mobileForm = this.instance.querySelector(SELECTORS.MOBILEFORM)
        this.mobileCTA = this.instance.querySelector(SELECTORS.MOBILECTA)
        this.mobileCloseButtons = Array.from(this.instance.querySelectorAll(SELECTORS.MOBILECLOSEBUTTON))

        this.submitMobileForm = this.submitMobileForm.bind(this)
        this.openMobileMenu = this.openMobileMenu.bind(this)
        this.closeMobileMenu = this.closeMobileMenu.bind(this)
        this.closeActiveSelect = this.closeActiveSelect.bind(this)

        this.registerHooks()
        this.registerListeners()
    }

    /*
    ** Register mobile listeners as well as our general keydown listener for focus trapping.
    */
    registerListeners() {
        if (this.mobileCTA) {
            this.mobileCTA.addEventListener('click', this.openMobileMenu)
            this.mobileCloseButtons.forEach(node => {
                node.addEventListener('click', this.closeMobileMenu)
            })
        }
        if (this.mobileForm) {
            this.mobileForm.addEventListener('submit', this.submitMobileForm)
        }

        Emitter.on('keydown', (event) => {
            if (this.activeSelect) {
                this.trapFocus(event)
            }

            if (event.which === GLOBAL_CONSTANTS.KEYS.ESC && this.activeSelect) {
                this.closeActiveSelect()
            }

        })
    }

    /*
    ** Query instances and register listeners. The generated data object
    ** is used for tracking equality when determining which select is open.
    */
    registerHooks() {

        const selectInstances = Array.from(this.instance.querySelectorAll(SELECTORS.SELECTINSTANCE))
        this.instanceData = []

        selectInstances.forEach(instance => {

            const focusableElements = this.registerFocusableElements(instance)

            const form = instance.querySelector(SELECTORS.FORM)
            const selectButton = instance.querySelector(SELECTORS.SELECTBUTTON)
            const cancelButton = instance.querySelector(SELECTORS.CANCELBUTTON)
            const hiddenInputs = Array.from(instance.querySelectorAll(SELECTORS.HIDDENINPUT))

            const data = {
                node: instance,
                id: instance.dataset.id,
                form,
                selectButton,
                ...focusableElements
            }

            // Set initial button label state. Used for when browser is navigated back.
            // The Form state isn't reset because we are programmatically submitting data.
            if (instance.dataset.resetFormOnPageLoad === 'true') {
                form.reset()
            }
            const initialState = serializeForm(form)
            const url = new URL(window.location)
            const hasParams = [...url.searchParams.keys()]
            if (Object.keys(initialState).length && instance && !hasParams.length) {
                this.updateSelectLabel(initialState, data)
            } else {
                const param = url.searchParams.get(instance.dataset.id)
                if (param) {
                    if (param.indexOf(',' > 0)) {
                        // This is a hack for the media compaign so that we can send out urls to show all jobs in the bay area
                        // http://...careers/all-departments/?location=san-francisco-ca,oakland-ca,...
                        // without this the component breaks
                        const params = param.split(',')
                        params.forEach(function(param) {
                            const input = instance.querySelector(`input[value="${param}"]`)
                            if ( input && input.type === 'radio' ) {
                                input.checked = true
                            }
                        })
                    } else {
                        const input = instance.querySelector(`input[value="${param}"]`)
                        if ( input && input.type === 'radio' ) {
                            input.checked = true
                        }
                    }
                }
            }

            this.instanceData.push(data)

            selectButton.addEventListener('click', (event) => {
                event.preventDefault()
                this.onSelectButtonClick.call(this, data)
            })
            if (form) {
                form.addEventListener('submit', (event) => {
                    event.preventDefault()
                    this.applyFilters.call(this, event, data)
                })
            }

            hiddenInputs.forEach(item => {
                item.addEventListener('click', (event) => {
                    this.changeInnerHTMLAndClose(event)
                })
            })

            if (cancelButton) {
                cancelButton.addEventListener('click', this.closeActiveSelect)
            }

        })

        if ( this.instance.dataset.type === 'inline' ) {
            this.inlineSubmitButton = this.instance.querySelector(SELECTORS.INLINESUBMITBUTTON)
            this.inlineSubmitButton.addEventListener('click', (event) => {
                event.preventDefault()
                this.submitInlineInstance.call(this)
            })
        }

    }

    /*
    ** Generate form GET url for page refresh
    */
    generateURL(data, href = document.location, base) {
        const url = new URL(href, base)
        if (this.type === 'blog') {
            Object.keys(data).forEach(key => {
                if (key === 'category') {
                    const value = data[key].split(':')
                    if (value.length === 2) {
                        url.searchParams.delete('type')
                        url.searchParams.delete('category')
                        url.searchParams.set(value[0], value[1])
                    } else {
                        url.pathname = data[key]
                    }
                } else {
                    url.searchParams.set(key, data[key])
                }
            })
        } else {
            Object.keys(data).forEach(key => {
                url.searchParams.set(key, data[key])
            })
        }
        return url.toString()
    }

    /*
    ** Responsible for closing and removing the current select context.
    ** Deleting the key is necesarry as pointers remained even when setting to null
    */
    closeActiveSelect(e) {
        if (e) {e.preventDefault()}

        !Object.hasOwn(this.activeSelect, 'mobile') && this.activeSelect.node.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        delete this.activeSelect
    }

    changeInnerHTMLAndClose(event) {
        if (!Object.hasOwn(this.activeSelect, 'mobile')) {
            const activeButton = this.activeSelect.node.querySelector(SELECTORS.SELECTLABEL)
            activeButton.innerHTML = event.currentTarget.dataset.label

            if (whatInput.ask() === 'mouse') {
                this.closeActiveSelect()
            }
        }
    }

    /*
    ** Click callback for triggering desktop select.
    */
    onSelectButtonClick(data) {
        if (this.activeSelect !== data) {
            this.activeSelect && this.closeActiveSelect()
            data.node.classList.toggle(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
            this.activeSelect = data
        } else if (this.activeSelect === data) {
            this.closeActiveSelect()
        }
    }

    /*
    ** Submit inline version of the form
    */
    submitInlineInstance() {
        let formData = {}
        this.instanceData.forEach(instance => {
            formData = {...formData, ...serializeForm(instance.form)}
        })
        const url = this.generateURL(formData, this.inlineSubmitButton.dataset.href, document.location.origin)
        window.location.href = url
    }

    /*
    ** Update the select label with current selection
    */
    updateSelectLabel(formState, instance) {
        const formKey = Object.keys(formState)[0]
        const formValue = formState[formKey]
        const input = instance.node.querySelector(`input[value="${formValue}"]`)
        const selectLabelEl = instance.selectButton.querySelector(SELECTORS.SELECTLABEL)
        selectLabelEl.innerHTML = input.dataset.label
    }

    /*
    ** Apply filters function. Used for both desktop and mobile.
    */
    applyFilters(event, instance, callback = this.closeActiveSelect) {
        const data = serializeForm(event.target)

        if (Object.keys(data).length && instance.node) {
            this.updateSelectLabel(data, instance)
        }

        if (this.type === 'dynamic') {
            Emitter.emit(GLOBAL_CONSTANTS.EVENTS.FORM_SUBMIT, {id: instance.id, data})
        } else if (this.type === 'email-capture') {
            console.log('no need to submit form, this is the email capture')
        } else {
            const url = this.generateURL(data)
            window.location.href = url
        }

        callback()
    }

    submitMobileForm(event) {
        event.preventDefault()
        this.applyFilters.call(this, event, {id: 'mobile'}, this.closeMobileMenu)
    }

    openMobileMenu(event) {
        event.preventDefault()
        this.activeSelect && this.closeActiveSelect()
        this.mobileMenu.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        this.activeSelect = {
            mobile: true,
            ...this.registerFocusableElements(this.mobileMenu)
        }
    }

    closeMobileMenu(event) {
        if (event) {event.preventDefault()}
        this.closeActiveSelect()
        this.mobileMenu.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        Emitter.emit(GLOBAL_CONSTANTS.EVENTS.CLOSE_MOBILE_MENU)
    }

    /*
    ** Query our select node/wrappers for focusable elements. Determine first/last and return.
    */
    registerFocusableElements(node) {
        const focusableEls = node.querySelectorAll('a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select')
        const firstFocusableEl = focusableEls[0]
        const lastFocusableEl = focusableEls[focusableEls.length - 1]
        return {
            focusableEls,
            firstFocusableEl,
            lastFocusableEl
        }
    }

    trapFocus(event) {
        const isTabPressed = (event.key === 'Tab' || event.keyCode === 9)
        if (!isTabPressed) {
            return
        }
        if ( event.shiftKey ) /* shift + tab */ {
            if (document.activeElement === this.activeSelect.firstFocusableEl) {
                this.activeSelect.lastFocusableEl.focus()
                event.preventDefault()
            }
        } else /* tab */ {
            if (document.activeElement === this.activeSelect.lastFocusableEl) {
                this.activeSelect.firstFocusableEl.focus()
                event.preventDefault()
            }
        }
    }

}


export const FormSelectComponent = {
    'name': 'FormSelect',
    'class': SELECTORS.COMPONENT,
    'Source': FormSelect
}

import Cookies from 'js-cookie'

const SELECTORS = {
    COMPONENT: '.js-service-area-select'
}

export default class ServiceAreaSelect {
    constructor(el) {
        let selectElement = el.getElementsByClassName('service-area-select').item(0)
        el.getElementsByClassName('switch-service-area').item(0).addEventListener('click', () => {
            let twoMinutesFromNow = new Date(new Date().getTime() + 2 * 60 * 1000)
            Cookies.set('service_area_changed', 'true', {expires: twoMinutesFromNow})
            let addressValue = window.location.href
            let prefix = addressValue.indexOf('?') === -1 ? '?' : '&'
            window.location.href = addressValue + prefix + 'service_area=' + selectElement.value
        })
    }
}

export const ServiceAreaSelectComponent = {
    'name': 'ServiceAreaSelect',
    'class': SELECTORS.COMPONENT,
    'Source': ServiceAreaSelect
}

/*eslint  semi: 0*/
import React, {Component, Fragment} from 'react'
import classNames from 'classnames'
import smoothscroll from 'smoothscroll-polyfill'

export default class ProvidersPagination extends Component {

    constructor(props) {
        super(props)
        this.scrollToMyRef = this.scrollToMyRef.bind(this)
    }

    componentDidMount() {
        smoothscroll.polyfill()
    }

    scrollToMyRef() {
        window.scrollTo({
            top: this.props.refProp.current.offsetParent.offsetTop - 82, // Substracted value is to account for header height
            behavior: 'smooth'
        })
    }

    render() {
        const {
            paginatedResults,
            setPaginationIndex,
            paginationIndex,
            onViewAllClick
        } = this.props

        const totalCount = paginatedResults.length

        const activePage = paginationIndex + 1

        let firstItem
        let firstMiddleItem
        let middleItem
        let lastMiddleItem
        let lastItem
        paginatedResults.map((count, i) => {
            const iOne = i + 1
            const firstPage = i === 0
            const lastPage = i === totalCount - 1

            if (firstPage) {
                firstItem = (
                    <li tabIndex="0" className="pagination__item" key={i} onClick={e => {setPaginationIndex(e, 0); this.scrollToMyRef(); }} onKeyPress={e => { (e.key === 'Enter') && (setPaginationIndex(e, 0), this.scrollToMyRef()) }}>
                        <span role="link" aria-label={'Page ' + iOne} aria-current={iOne === activePage && 'page'} className={classNames('pagination__number', {'-active': iOne === activePage})}>1</span>
                    </li>
                )
            } else if (iOne === (activePage - 1)) {
                firstMiddleItem = (
                    <Fragment>
                        {activePage !== 3 &&
                            <li className="pagination__item">
                                <span>...</span>
                            </li>
                        }
                        <li tabIndex="0" className="pagination__item" key={i} onClick={e => {setPaginationIndex(e, i); this.scrollToMyRef();}} onKeyPress={e => { (e.key === 'Enter') && (setPaginationIndex(e, i), this.scrollToMyRef()) }}>
                            <span role="link" aria-label={'Page ' + iOne} aria-current={iOne === activePage && 'page'} className={classNames('pagination__number', {'-active': iOne === activePage})}>{iOne}</span>
                        </li>
                    </Fragment>
                )
            } else if (iOne === activePage) {
                middleItem = (
                    <li tabIndex="0" className="pagination__item" key={i} onClick={e => {setPaginationIndex(e, (activePage - 1)); this.scrollToMyRef();}} onKeyPress={e => { (e.key === 'Enter') && (setPaginationIndex(e, activePage), this.scrollToMyRef()) }}>
                        <span role="link" aria-label={'Page ' + iOne} aria-current={iOne === activePage && 'page'} className={classNames('pagination__number', {'-active': iOne === activePage})}>{iOne}</span>
                    </li>
                )
            } else if (iOne === (activePage + 1) && !lastPage) {
                lastMiddleItem = (
                    <Fragment>
                        <li tabIndex="0" className="pagination__item" key={i} onClick={e => {setPaginationIndex(e, activePage); this.scrollToMyRef();}} onKeyPress={e => { (e.key === 'Enter') && (setPaginationIndex(e, activePage), this.scrollToMyRef()) }}>
                            <span role="link" aria-label={'Page ' + iOne} aria-current={iOne === activePage && 'page'} className={classNames('pagination__number', {'-active': iOne === activePage})}>{iOne}</span>
                        </li>
                        <li className="pagination__item">
                            <span>...</span>
                        </li>
                    </Fragment>
                )
            } else if (lastPage) {
                lastItem = (
                    <li tabIndex="0" className="pagination__item" key={i} onClick={e => {setPaginationIndex(e, (totalCount - 1)); this.scrollToMyRef(); }} onKeyPress={e => { (e.key === 'Enter') && (setPaginationIndex(e, (totalCount - 1)), this.scrollToMyRef()) }}>
                        <span role="link" aria-label={'Page ' + iOne} aria-current={iOne === activePage && 'page'} className={classNames('pagination__number', {'-active': iOne === activePage})}>{paginatedResults.length}</span>
                    </li>
                )
            }
        })

        return (
            <nav className="pagination">
                <ul>
                    {paginationIndex !== 0 &&
                        <li tabIndex="0" role="link" aria-label="Previous Page" className="pagination__item" onClick={e => {setPaginationIndex(e, paginationIndex - 1); this.scrollToMyRef(); }} onKeyPress={e => { (e.key === 'Enter') && (setPaginationIndex(e, paginationIndex - 1), this.scrollToMyRef()) }}>
                            <span>
                                <svg style={{transform: 'rotate(180deg)'}} className="pagination__arrow" viewBox="0 0 6 9" xmlns="http://www.w3.org/2000/svg"><path d="M.75 8.25l3.5-3.5-3.5-3.5" fillRule="nonzero" strokeWidth="2" fill="none"/></svg>
                            </span>
                        </li>
                    }
                    {firstItem}
                    {firstMiddleItem}
                    {middleItem}
                    {lastMiddleItem}
                    {lastItem}
                    {paginatedResults.length !== paginationIndex + 1 &&
                        <li tabIndex="0" role="link" aria-label="Next Page" className="pagination__item" onClick={e => {setPaginationIndex(e, paginationIndex + 1); this.scrollToMyRef();}} onKeyPress={e => { (e.key === 'Enter') && (setPaginationIndex(e, paginationIndex + 1), this.scrollToMyRef()) }}>
                            <span>
                                <svg className="pagination__arrow" viewBox="0 0 6 9" xmlns="http://www.w3.org/2000/svg"><path d="M.75 8.25l3.5-3.5-3.5-3.5" fillRule="nonzero" strokeWidth="2" fill="none"/></svg>
                            </span>
                        </li>
                    }
                    {paginatedResults.length > 1 &&
                        <li className="pagination__item" role="link" aria-label="View All" tabIndex="0">
                            <a className="pagination__number" href="#" onClick={e => onViewAllClick(e, this.scrollToMyRef)}>View All</a>
                        </li>
                    }
                </ul>
            </nav>
        )
    }
}

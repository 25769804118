import $ from 'jquery'

const CLASSES = {
    COMPONENT: '.js-stats'
}

export default class StatsAnimatedClass {
    constructor() {
        window.$ = $
        this.container = document.querySelector(CLASSES.COMPONENT)
        /* TBD component */
    }
}

export const StatsAnimated = {
    'name': 'Stats Animated',
    'class': CLASSES.COMPONENT,
    'Source': StatsAnimatedClass
}

import React, {Fragment} from 'react'
import axios from 'axios'
import { render } from 'react-dom'
import moment from 'moment'
import 'moment-timezone'

import { GLOBAL_CONSTANTS } from 'utils/constants'

const CLASSES = {
    COMPONENT: '.js-office-appointments-list'
}
const SIGNUP_URL = 'https://members.app.onemedical.com/pt/registration/register'
const MAX_NUMBER_OF_APPOINTMENTS_TO_SHOW = 5

class OfficeAppointmentsList extends React.Component {
    constructor(props) {
        super(props)
        this.wrapper = this.props.element
        const thirtyMinutesFromNow = moment().add(30, 'minutes')

        this.primaryOffice = this.props.data.office

        // Make sure we apply the proper time zone & set "kids only" label for pediatric-only appts
        this.primaryOffice.appointments.forEach(a => {
            a.date = moment.utc(a.startAt).tz(this.getTimezoneString()).format('YYYY-MM-DD')
            a.endAt = moment.utc(a.startAt).add(a.duration, 'minutes')

            if (a.provider_roles.includes(GLOBAL_CONSTANTS.APPOINTMENTS.PEDIATRIC_ROLE) &&
                a.duration === GLOBAL_CONSTANTS.APPOINTMENTS.PEDIATRIC_APPOINTMENT_LENGTH) {
                a.label = GLOBAL_CONSTANTS.APPOINTMENTS.PEDIATRIC_LABEL
            }
        })

        this.primaryOffice.appointments = this.primaryOffice.appointments.filter(a => {
            return moment.utc(a.startAt).isAfter(thirtyMinutesFromNow)
        })

        const currentAppointments = this.primaryOffice.appointments.slice(0, MAX_NUMBER_OF_APPOINTMENTS_TO_SHOW)

        // Pagination vars
        this.numAppointments = this.primaryOffice.appointments.length
        this.maxPage = Math.floor(this.numAppointments / MAX_NUMBER_OF_APPOINTMENTS_TO_SHOW) - 1

        // State vars
        this.state = {
            currentAppointments: currentAppointments,
            showPrevious: false,
            showNext: this.maxPage > 0,
            currentPage: 0
        }


    }

    groupBy(xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x)
            return rv
        }, {})
    }

    signupUrl(appointmentInventoryId = null) {
        let signupUrlString = `${SIGNUP_URL}?service_area_id=${this.props.currentServiceAreaId}`
        if (appointmentInventoryId !== null) {
            signupUrlString += `&appointment_inventory_id=${appointmentInventoryId}`
        }
        return signupUrlString
    }

    dateStringHeader(dateString) {
        const dateStringDate = moment(dateString, 'YYYY-MM-DD').startOf('day')
        const today = moment().startOf('day')
        const tomorrow = moment().add(1, 'days').startOf('day')
        if (dateStringDate.isSame(today)) {
            return 'Today, ' + dateStringDate.format('MMM D')
        } else if (dateStringDate.isSame(tomorrow)) {
            return 'Tomorrow, ' + dateStringDate.format('MMM D')
        } else {
            return dateStringDate.format('MMM D')
        }
    }

    getTimezoneString() {
        return {
            'sf': 'America/Los_Angeles',
            'nyc': 'America/New_York',
            'dc': 'America/New_York',
            'bos': 'America/New_York',
            'chi': 'America/Chicago',
            'la': 'America/Los_Angeles',
            'phx': 'America/Phoenix',
            'sea': 'America/Los_Angeles',
            'sd': 'America/Los_Angeles',
            'atl': 'America/New_York',
            'pdx': 'America/Los_Angeles',
            'oc': 'America/Los_Angeles',
            'atx': 'America/Chicago'
        }[this.props.officeServiceAreaCode]
    }

    officeAppointments() {
        const appointmentInventoriesByDate = this.groupBy(this.state.currentAppointments, 'date')
        return Object.keys(appointmentInventoriesByDate).map((dateString) => {
            return (
                <Fragment key={`${dateString}-date-group`}>
                    { this.appointmentInventoryDateHeader(dateString) }
                    {
                        appointmentInventoriesByDate[dateString].map((appointmentInventory) => {
                            return this.appointmentInventoryRow(appointmentInventory)
                        })
                    }
                </Fragment>
            )
        })
    }

    /**
     * Simple pagination
     */
    setPage(pageNumber = 0) {
        if (pageNumber <= this.maxPage) {
            const index = MAX_NUMBER_OF_APPOINTMENTS_TO_SHOW * pageNumber
            let currentAppts = this.primaryOffice.appointments.slice(index, index + MAX_NUMBER_OF_APPOINTMENTS_TO_SHOW)
            this.setState({
                currentAppointments: currentAppts,
                currentPage: pageNumber,
                showNext: pageNumber < this.maxPage,
                showPrevious: pageNumber > 0
            })
        }
    }

    officeHasAppointments() {
        return this.primaryOffice.appointments && this.primaryOffice.appointments.length && this.state.currentAppointments.length
    }

    appointmentInventoryDateHeader(dateString) {
        return (
            <tr className="dateHeader" key={dateString}>
                <th colSpan="4" className="office-appointments__table--th"><h5>{this.dateStringHeader(dateString)}</h5></th>
            </tr>
        )
    }

    appointmentInventoryRow(appointmentInventory) {
        return (
            <tr key={appointmentInventory['id'] + appointmentInventory['startAt'] + Math.random()}>
                <td className="office-appointments__table--td image">
                    <div className="providers-grid__item-wrapper">
                        <div className="providers-grid__item-content">
                            <div className={'office-appointments__item-image -loaded'} style={{ backgroundImage: `url('${appointmentInventory.provider_profile_image_url}')` }}/>
                        </div>
                    </div>
                </td>
                <td className="office-appointments__table--td name">
                    <h2 className="office-appointments__item-headline">{appointmentInventory.provider_display_name}</h2>
                </td>
                <td className="office-appointments__table--td duration">
                    <span className='appointment-duration'>{appointmentInventory['duration']} min</span>
                    <span className='appointment-label'>{appointmentInventory['label']}</span>
                </td>
                <td className="office-appointments__table--td time">
                    {moment(appointmentInventory['startAt']).tz(this.getTimezoneString()).format('h:mm A')}
                </td>
            </tr>
        )
    }

    appointmentOptions() {
        if (this.officeHasAppointments()) {
            return (
                <div className="office-appointments__timeslots-wrapper">
                    <table className="office-appointments__table" cellPadding="0" cellSpacing="0">
                        <tbody>
                            { this.officeAppointments() }
                        </tbody>
                    </table>
                </div>
            )
        }
    }

    render() {
        return (
            <div className="office-appointments">
                <div className="office-appointments__callout-block">
                    <span className="office-appointments__callout-block--header">
                        { this.officeHasAppointments() ? (
                            <>Want to see a provider?</>
                        ) : (
                            <>Want to see a provider?</>
                        )}
                    </span>
                    <p className="office-appointments__callout-block--body-copy">
                        Book an appointment today by becoming a One Medical member for just $199/year.
                    </p>
                    <a href={this.signupUrl()} className="-btn-pill -btn-pill-primary">Sign up to book</a>
                </div>
                <div className="office-appointments__wrapper">
                    <h4 className="office-appointments__headline">Appointments</h4>
                    { this.appointmentOptions() }
                    { this.state.showPrevious ? (<a className="previous-link" onClick={this.setPage.bind(this, this.state.currentPage - 1)}>Previous</a>) : ''}
                    &nbsp;
                    { this.state.showNext ? (<a className="next-link" onClick={this.setPage.bind(this, this.state.currentPage + 1)}>Next</a>) : ''}
                </div>
            </div>
        )
    }
}

export default class MountOfficeAppointmentsList {
    constructor(el) {
        this.el = el
        this.fetchAppointments()
    }

    fetchAppointments(req = '/api/appointments/office/') {
        let params = {
            cache_buster: Math.random().toString(24).substr(4, 4) // eslint-disable-line camelcase
        }

        axios.get(req.concat(this.el.dataset.officeId), {
            params: params
        })
            .then(res => {
                if (res.data) {
                    this.renderComponent(res.data)
                } else {
                    console.info('no appointments data found')
                }

            })
            .catch(err => {
                console.error(err)
            })
    }

    renderComponent(officeAppointmentData) {
        this.component = render(
            <OfficeAppointmentsList
                element={this.el}
                data={officeAppointmentData}
                currentServiceAreaId={this.el.dataset.currentServiceAreaId}
                officeServiceAreaCode={this.el.dataset.officeServiceAreaCode}
            />,
            this.el
        )
    }
}

export const OfficeAppointmentsListComponent = {
    'name': 'Office Appointments List',
    'class': CLASSES.COMPONENT,
    'Source': MountOfficeAppointmentsList
}

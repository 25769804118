import 'what-input'

import { modules } from 'utils/module-loader'
import Emitter from 'utils/emitter'

export default class BaseEntryClass {
    constructor() {
        this.detectJs()
        if (DEPLOYMENT_ENV !== 'local') {
            this.registerLogging()
        }
        this.registerEvents()
        this.instances = this.registerInstances()
    }

    /**
     * @desc If JS is available,
     * remove the noscript class from the body element.
     */
    detectJs() {
        document.body.classList.remove('noscript')
    }

    /**
     * @desc Create all instances of classes that are paired with
     * javascript components in the modules Array.
     * @return {Array.<Object>} Array of all module objects.
     */
    registerInstances() {
        return modules.map((module) => {
            const elements = Array.from(document.querySelectorAll(module.class))
            const references = elements.map(el => new module.Source(el))

            return {
                name: module.name,
                ref: references
            }
        })
    }

    registerLogging() {
        // Rollbar init code used to be here
    }

    /**
     * @desc - These events get instantiated once and bound to the window
     * so that there aren’t multiple events firing from each page module
     * @fires <App>#[resize]
     * @fires <App>#[scroll]
     * @fires <App>#[keydown]
     */
    registerEvents() {
        /** Fire resize event to emitter when window resizes */
        window.addEventListener('resize', () => {
            Emitter.emit('resize')
        })
        /** Fire scroll event to emitter when window scroll */
        window.addEventListener('scroll', () => {
            Emitter.emit('scroll')
        })
        /** Fire keydown event to emitter on window keydown */
        window.addEventListener('keydown', (e) => {
            Emitter.emit('keydown', e)
        })
    }
}

import axios from 'axios'
import Cookies from 'js-cookie'
import Emitter from 'utils/emitter'
import { GLOBAL_CONSTANTS } from 'utils/constants'
import { MEDIA_DOWNLOAD_COOKIE_NAME } from 'components/MediaResourceDownloadLink'

/**
 * Loops through eloqua form inputs runs validation.
 * This code is provided by Eloqua and reformatted
 */

const CLASSES = {
    COMPONENT: '.js-eloqua-form',
    FORM: '.js-form',
    INPUT: '.js-form-input',
    SELECT: '.js-form-select',
    TEXTAREA: '.js-form-textarea',
    REQUIRED: 'js-required',
    EMAIL_VALIDATION: 'js-email-validation',
    ZIP_VALIDATION: 'js-zip-validation',
    INVALID_CLASS: '.LV_invalid_field',
    CONFIRMATION: '.js-form-confirmation',
    HIDE_ON_CONFIRMATION: '.js-form-hide-on-confirmation',
    EMAIL_CAPTURE_FORM_WITH_FILTERS: 'js-eloqua-email-capture-form-with-filters'
}

export default class EloquaFormValidation {
    /**
     * @desc Set up validation for eloqua whitepaper form
     * @param {HTMLElement} el - Element that contains possible validations
     *
     */

    constructor(element) {
        this.el = element
        this.form = this.el.querySelector(CLASSES.FORM)
        this.inputs = Array.from(this.el.querySelectorAll(CLASSES.INPUT))
        this.selects = Array.from(this.el.querySelectorAll(CLASSES.SELECT))
        this.textareas = Array.from(this.el.querySelectorAll(CLASSES.TEXTAREA))

        this.formName = this.el.querySelector('input[name=elqFormName]').value

        this.formConfirmation = this.el.querySelector(CLASSES.CONFIRMATION)
        this.formHideOnConfirmation = this.el.querySelector(CLASSES.HIDE_ON_CONFIRMATION)
        this.formElements = [...this.inputs, ...this.selects, ...this.textareas]
        this.validatorList = []

        this.submitForm = this.submitForm.bind(this)

        this.initialize()
    }

    /**
     * @desc initialize the class functions to validate form
     */
    initialize() {
        this.registerLEvents()
        this.validateForm()

    }

    registerLEvents() {
        this.form.addEventListener('submit', this.submitForm)
    }

    /**
     * @desc Handles validation of the form.
     * Eloqua includes "LiveValidation" library, so we use it here too.
     * docs - https://web.archive.org/web/20140209045258/http://livevalidation.com/documentation
     */
    _addEmailValidations(validator) {
        validator
            .add(Validate.Email)
        this.validatorList.push(validator)
    }
    _addZipValidations(validator) {
        validator
            .add(Validate.Numericality)
            .add(Validate.Length, { is: 5 })

        this.validatorList.push(validator)
    }
    _addPresenceValidations(validator) {
        validator.add(Validate.Presence, {
            failureMessage: 'This field is required'
        })
        this.validatorList.push(validator)
    }
    _buildValidator(element) {
        return new LiveValidation(element, {
            onValid: () => {},
            onlyOnBlur: true
        })
    }
    validateForm () {
        const formElements = [...this.inputs, ...this.selects, ...this.textareas]
        formElements.forEach((element) => {
            if (element.classList.contains(CLASSES.EMAIL_VALIDATION)) {
                const emailValidator = this._buildValidator(element)
                this._addEmailValidations(emailValidator)
            } else if (element.classList.contains(CLASSES.ZIP_VALIDATION)) {
                const zipValidator = this._buildValidator(element)
                this._addZipValidations(zipValidator)
            }

            if (element.classList.contains(CLASSES.REQUIRED)) {
                const presenceValidator = this._buildValidator(element)

                this._addPresenceValidations(presenceValidator)
            }
        })
    }

    submitForm(e) {
        e.preventDefault()
        const isValid = LiveValidation.massValidate(this.validatorList)

        if ( isValid ) {
            const formData = new FormData(this.form)
            // this block of code is used to grab the selections
            // that are in the mobile form, which is nested inside of
            // the email capture form
            if (this.form.classList.contains(CLASSES.EMAIL_CAPTURE_FORM_WITH_FILTERS)) {
                if (this.form.querySelector('.js-form-select-mobile-form')) {
                    let mobileFormData = new FormData(this.form.querySelector('.js-form-select-mobile-form'))
                    if (Array.from(mobileFormData.entries()).length > 0) {
                        Array.from(mobileFormData.entries()).forEach((entry) =>{
                            formData.append(entry[0], entry[1])
                        })
                    }
                }
            }
            const req = this.form.action

            axios.post(req, formData)
                .then(() => {
                    this.formHideOnConfirmation.classList.add(GLOBAL_CONSTANTS.CLASSES.HIDDEN)
                    this.form.classList.add(GLOBAL_CONSTANTS.CLASSES.HIDDEN)
                    this.formConfirmation.classList.remove(GLOBAL_CONSTANTS.CLASSES.HIDDEN)
                    this.formConfirmation.classList.add(GLOBAL_CONSTANTS.CLASSES.VISIBLE)

                    if (this.formName === 'mediaCenterDownloadRequest') {
                        Cookies.set(MEDIA_DOWNLOAD_COOKIE_NAME, 'true')
                        setTimeout(() => {
                            window.location = '/mediacenter/'
                        }, 1000)
                    }
                }).catch((error) => {
                    Emitter.emit('exception', {
                        error,
                        req
                    })
                })
        }

    }

}

/**
 * @desc Whitepaper validation component definition used in module-loader
 */

export const EloquaFormValidationComponent = {
    'name': 'EloquaFormValidation',
    'class': CLASSES.COMPONENT,
    'Source': EloquaFormValidation
}

import {v4 as uuidv4} from 'uuid'
import Cookies from 'js-cookie'
import * as LDClient from 'launchdarkly-js-client-sdk'

/**
 * LaunchDarkly set up and initialize
 *
 * LDClient loaded from script in <head> of base_layout.html
 * /static/js/ldclient.min.js
 *
 * @see https://launchdarkly.github.io/js-client-sdk/index.html
 * @see https://docs.launchdarkly.com/sdk/client-side/javascript
 */
export default class LaunchDarkly {

    constructor() {
        window.__uspapi('getUSPData', 1, this.initLDClient)
    }

    /**
     * Initialize LaunchDarkly client
     * in compliance with Global Privacy Control
     *
     * @param uspString USP consent string
     */
    initLDClient(uspString) {
        if (uspString !== '1YYY') {
            let uuid = Cookies.get('onem')
            if (!uuid) {
                uuid = uuidv4()
                // set identity cookie to expire in 10 years
                Cookies.set('onem', uuid, {domain: '.onemedical.com', secure: true, expires: 3650})
            }
            // eslint-disable-next-line no-undef
            window.LDClient = LDClient.initialize(LAUNCHDARKLY_CLIENT_SIDE_ID, {
                key: uuid,
                anonymous: true,
                custom: {'application': 'omdc'}
            })
        }
    }
}

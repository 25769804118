import $ from 'jquery'

const CLASSES = {
    COMPONENT: '.om4b-total-cost-accordion'
}

export default class OM4BTotalCostAccordionClass {
    constructor() {
        window.$ = $
        this.container = document.querySelector(CLASSES.COMPONENT)

        $('.metric-card').on('click', function() {
            $('.metric-card').removeClass('active')
            $(this).addClass('active')
        })

        $('.metric-card').click(function() {
            if (!$(this).hasClass('active')) {
                $('.metric-card .active').removeClass('active')
                $(this).addClass('active')
            }
        })

        $('.accordion-arrows button').click(function(e) {
            e.stopPropagation()
        })

        $('.arrow-next').click(function() {
            var active = $('.metric-card.active')

            if (active.is(':last-child')) {
                $('ul#metrics-cardslist li:first-child').addClass('active')
                active.removeClass('active')
            }

            active.next().addClass('active')
            active.removeClass('active')
        })

        $('.arrow-prev').click(function() {
            var active = $('ul#metrics-cardslist li.active')

            if (active.is(':first-child')) {
                $('ul#metrics-cardslist li:last-child').addClass('active')
                active.removeClass('active')
            }

            active.prev().addClass('active')
            active.removeClass('active')
        })

    }
}

export const OM4BTotalCostAccordion = {
    'name': 'OM4B Total Cost Accordion',
    'class': CLASSES.COMPONENT,
    'Source': OM4BTotalCostAccordionClass
}
import React from 'react'
import { render } from 'react-dom'

import { GLOBAL_CONSTANTS } from 'utils/constants'

const CLASSES = {
    COMPONENT: '.js-video-lightbox-present',
    REACT_WRAPPER: '.js-video-lightbox',
    LIGHTBOX_INITIATOR: '.js-lightbox-initiator'
}

class VideoLightbox extends React.Component {
    constructor(props) {
        super(props)
    }

    state = {
        visible: false,
        videoId: ''
    }

    /**
     * Attach listener to window event for lightbox
     */
    attachListeners() {
        window.addEventListener(this.props.eventName, this.handleLightboxOpening)
    }

    /**
     * Handle closing the video
     */
    handleLightboxClose = () => {
        this.setState({
            visible: false
        })

        document.querySelector('body').classList.remove(GLOBAL_CONSTANTS.CLASSES.NO_SCROLL)
    }

    /**
     * Handle the opening of the video lightbox
     */
    handleLightboxOpening = (event) => {
        this.setState({
            visible: true,
            videoId: event.detail.videoId
        })
        document.querySelector('body').classList.add(GLOBAL_CONSTANTS.CLASSES.NO_SCROLL)
    }

    /**
     * React's componentDidMount lifecycle method
     */
    componentDidMount() {
        this.attachListeners()
    }

    /**
     * React's render lifecycle method
     */
    render() {
        return (
            <>
                {this.state.visible &&
                    <div className="video-lightbox">
                        <div className="video-lightbox__background" onClick={this.handleLightboxClose} />
                        {this.state.videoId &&
                            <div className="video__player-wrapper">
                                <div className="video-lightbox__close" onClick={this.handleLightboxClose} />

                                {/* Start -- Wistia Embed Code */}
                                <div className="video__player-wrapper--padding">
                                    <div className="video__player-wrapper--responsive-wrapper">
                                        <div className={`video__player-wrapper--embed wistia_embed wistia_async_${this.state.videoId} seo=false videoFoam=true`}>&nbsp;</div>
                                    </div>
                                </div>
                                {/* End -- Wistia Embed Code */}
                            </div>
                        }
                    </div>
                }
            </>
        )
    }
}

export default class MountVideoLightbox {
    constructor(element) {
        this.el = element
        this.reactWrapper = element.querySelector(CLASSES.REACT_WRAPPER)
        this.lightboxInitiators = element.querySelectorAll(CLASSES.LIGHTBOX_INITIATOR)
        this.eventName = `lightbox-${this.el.dataset.videoLightboxComponentId}`

        this.initialize()
    }

    /**
     * Initialize the component and attach events
     */
    initialize() {
        this.renderComponent()
        this.attachEventHandlers()
    }

    /**
     * Attaches the events for card click
     */
    attachEventHandlers() {
        this.lightboxInitiators.forEach(button => {
            button.addEventListener('click', (e) => {
                const videoid = e.currentTarget.dataset.videoid

                if (videoid) {
                    e.preventDefault()
                    e.stopPropagation()

                    const videoDetail = { videoId: videoid }
                    const lightboxEvent = new CustomEvent(this.eventName,
                        { detail: videoDetail })

                    window.dispatchEvent(lightboxEvent)
                }
            })
        })
    }

    /**
     * Renders the react component
     */
    renderComponent() {
        this.component = render(
            <VideoLightbox
                eventName={this.eventName}/>,
            this.reactWrapper
        )
    }
}

export const VideoLightboxComponent = {
    'name': 'Video Lightbox',
    'class': CLASSES.COMPONENT,
    'Source': MountVideoLightbox
}
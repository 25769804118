import React from 'react'
import moment from 'moment'
import 'moment-timezone'

const CLASSES = {
    COMPONENT: '.js-office-hours',
    OFFICE_HOURS_CONTAINER: '.office-info__detailed-hours-container',
    TOGGLE_HOURS_TEXT: '.toggle-hours-text',
    OPEN_AREA: '.office-info__detailed-hours-container-open',
    BUTTON: '.toggle-hours'
}

class OfficeHours extends React.Component {
    constructor(element) {
        super(element)
        this.element = element
        this.toggleButton = this.element.querySelector(CLASSES.BUTTON)
        this.hoursArea = this.element.querySelector(CLASSES.OFFICE_HOURS_CONTAINER)
        this.toggleHoursText = this.element.querySelector(CLASSES.TOGGLE_HOURS_TEXT)
        this.initialize()
    }

    initialize() {
        this.convertHours()
        this.registerButton()
    }

    registerButton() {
        this.toggleButton.addEventListener('click', this.toggleArea.bind(this))
    }

    toggleArea() {
        if (this.hoursArea.classList.contains(CLASSES.OPEN_AREA)) {
            this.hoursArea.classList.remove(CLASSES.OPEN_AREA)
            this.toggleHoursText.innerHTML = 'Show'
        } else {
            this.hoursArea.classList.add(CLASSES.OPEN_AREA)
            this.toggleHoursText.innerHTML = 'Hide'
        }
    }
    // Using moment to convert open and close time from 24hr to 12hr format.
    convertHours() {
        this.element.querySelectorAll('.opens,.closes').forEach((hourSpan) => {
            hourSpan.innerHTML = moment(hourSpan.innerHTML, ['HH:mm']).format('h:mm A')
        })
    }

}




export const OfficeHoursComponent = {
    'name': 'Office Hours',
    'class': CLASSES.COMPONENT,
    'Source': OfficeHours
}
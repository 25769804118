
import React from 'react'
import axios from 'axios'
import Emitter from 'utils/emitter'
import { render } from 'react-dom'
import { debounce, orderBy } from 'lodash-es'

import { mapLoader, GLOBAL_CONSTANTS, isStandardTablet } from 'utils/constants'
import mapStyles from './helpers/mapStyles'
import FilterGroup from 'base/components/FilterGroup'

const CLASSES = {
    COMPONENT: '.office-map',
    MAP_EL: '.js-map-refactor', // TODO: remove refactor sufix after removing the old map
    OFFICE_LIST: '.office-list'
}

const ERRORS = {
    DISTANCE: 'We currently do not have an office 50 miles from your location. We do however offer 24/7 virtual services for members that you can access through our app.',
    PERMISSIONS: 'Location services is currently not enabled for your browser. Please enable and try again.',
    GENERAL: 'Sorry, location services encountered an error. Play try again later.',
    EMPTY_SEARCH: 'Sorry, there are no results for your search parameters.'
}

class OfficesMap extends React.Component {

    constructor(props) {
        super(props)
        this.wrapper = this.props.element
        this.map = {}
        this.markers = []
        this.serviceAreas = []
        this.fullLocationData = this.props.data
        this.currentLocationData = this.props.data
        this.setURL = this.setURL.bind(this)
    }

    state = {
        officesData: this.props.data.offices,
        mapOn: false,
        formattedAddress: '',
        errorOn: false,
        errorMessage: '',
        otherServiceAreaName: '',
        otherServiceAreaCode: '',
        filters: this.setFilterState()
    }

    /**
     * Pre-select map filters
     *
     * At the time of writing, we only have "kids and family" as possible filters, but as we expand our service
     * offering, we could have others. This would be a place where we could pre-select the filters based on
     * whatever conditions apply.
     */
    initializeMap() {
        let preSelectedFilters = {}

        let pediatricParam = (new URL(window.location.href)).searchParams.get('pediatric_services') || 'false'

        if (document.referrer.match(/kids/) !== null || pediatricParam && pediatricParam === 'true') {
            preSelectedFilters = { pediatric_services: 'on' } // eslint-disable-line camelcase
        }

        let seniorsParam = (new URL(window.location.href)).searchParams.get('sees_seniors') || 'false'
        if (seniorsParam === 'true') {
            preSelectedFilters['sees_seniors'] = 'on'
        }

        this.filterMapHandler(preSelectedFilters)
    }

    /**
     * Create and display the map for the offices
     */
    createMap(officeData = this.state.officesData) {
        this.mapWrapper = this.wrapper.querySelector(CLASSES.MAP_EL)

        mapLoader.load(google => {
            this.geocoder = new google.maps.Geocoder()
            this.google = google
            this.serviceAreaPosition = {
                lat: this.fullLocationData.latitude,
                lng: this.fullLocationData.longitude
            }


            this.map = new google.maps.Map(this.mapWrapper, {
                zoom: this.getCenteredZoomLevel(this.serviceAreaPosition.lat, this.fullLocationData.max),
                center: this.serviceAreaPosition,
                disableDefaultUI: true,
                zoomControl: true,
                styles: mapStyles
            })

            this.setMarkers(google, officeData)
        })
    }

    /**
     * Clear filters
     */
    clearFilters() {
        let filtersObj = {...this.state.filters}

        Object.keys(this.state.filters).forEach(key => {
            filtersObj[key] = false
        })

        this.filterMapHandler(filtersObj)
    }

    setFilterState() {
        const PARAM = {
            PEDS: (new URL(window.location.href)).searchParams.get('pediatric_services') || 'false',
            SENIORS: (new URL(window.location.href)).searchParams.get('sees_seniors') || 'false'
        }

        return {
            pediatric_services: !!(PARAM.PEDS && PARAM.PEDS === 'true'), // eslint-disable-line camelcase
            sees_seniors: !!(PARAM.SENIORS && PARAM.SENIORS === 'true') // eslint-disable-line camelcase
        }
    }

    /**
     * Filter the map according to the selected items on the filter
     * @param {Object} data Filters that were selected by the user
     */
    filterMapHandler = (data) => {
        // apply data to this.state.filters
        let filters = this.state.filters
        Object.keys(data).forEach(k => {
            filters[k] = (data[k] === 'on')
        })
        this.setState({ filters: filters })

        let filteredOffices = this.fullLocationData.offices.filter(office => {
            if (!this.state.filters.sees_seniors &&
                !this.state.filters.pediatric_services) {
                // no filters selected
                return true
            }
            // pass when the filter is checked and the office matches that filter
            const seniorsFilter = this.state.filters.sees_seniors && office.sees_seniors // this field is a numeric boolean
            const pedsFilter = this.state.filters.pediatric_services && office.pediatric_services === 'True'
            return seniorsFilter || pedsFilter
        })

        this.setState({officesData: filteredOffices})
        this.createMap(filteredOffices)

        if (filteredOffices.length) {
            this.setState({
                errorOn: false
            })
        } else {
            this.setState({
                errorOn: true,
                errorMessage: ERRORS.EMPTY_SEARCH
            })
        }
    }


    setURL() {
        let newURL =
            window.location.origin
            + window.location.pathname

        for (let [key, value] of Object.entries(this.state.filters)) {
            const isFirst = (Object.keys(this.state.filters)[0] === key)
            const separator = isFirst ? '?' : '&'

            if (key === 'service_area') {
                newURL += separator + `service_area_filter=${value}`
            }
            else if (value !== undefined && value !== '' && key !== 'filters') {
                let parsedValue = (value === 'on') ? true : value
                newURL += separator + `${key}=${parsedValue}`
            }
        }

        window.history.pushState({ path: newURL }, '', newURL)
    }


    /**
     * SearchBox input handler
     */
    searchBoxInputHandler(event) {
        this.setState({
            formattedAddress: event.target.value
        })
    }


    /**
     * Load filters and search bar
     */
    loadFilters() {
        return (
            <div className="address-form js-address-form col-md-12 col-xs-12">
                <div className="address-form__wrapper">
                    <form onSubmit={this.handleSubmit.bind(this)} className="address-form__search-wrapper col-md-6 col-xs-12">
                        <div className="address-form__input-wrapper">
                            <label className="-visually-hidden" htmlFor="addressSearch">Search: </label>
                            <input name="address" id="addressSearch" className="address-form__input" placeholder="Enter your address"
                                value={this.state.formattedAddress}
                                onChange={this.searchBoxInputHandler.bind(this)} />
                            <button type="submit" className="address-form__btn" aria-label="Submit">
                                <svg className="address-form__icon" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g transform="translate(-321.000000, -24.000000)" fill="#004D49" fillRule="nonzero">
                                            <g transform="translate(321.000000, 24.000000)">
                                                <path d="M6.5,11 C8.98528137,11 11,8.98528137 11,6.5 C11,4.01471863 8.98528137,2 6.5,2 C4.01471863,2 2,4.01471863 2,6.5 C2,8.98528137 4.01471863,11 6.5,11 Z M11.7489008,10.3346872 L15.5961941,14.1819805 L14.1819805,15.5961941 L10.3346872,11.7489008 C9.2597335,12.5355664 7.9341039,13 6.5,13 C2.91014913,13 0,10.0898509 0,6.5 C0,2.91014913 2.91014913,0 6.5,0 C10.0898509,0 13,2.91014913 13,6.5 C13,7.9341039 12.5355664,9.2597335 11.7489008,10.3346872 Z" />
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </form>

                    {/* Filters */}
                    <div className="address-form__filters-wrapper col-md-6 col-xs-12">
                        <FilterGroup
                            alignRight
                            headline="Filter Locations"
                            selects={this.generateSelectModel()}
                            applySelect={this.filterMapHandler}
                            filters={this.state.filters}
                        />
                    </div>
                </div>
                {/* Found offices in another service area */}
                {this.state.otherServiceAreaName && this.state.otherServiceAreaName.length ?
                    <div className="address-form__error-wrapper--mobile">
                        <span className="address-form__error js-address-form-error">{`
                                    Hmm. We found offices closer to the location you’ve entered in our ${this.state.otherServiceAreaName} region. Do you want to `}
                        <a className="address-form__link" href={`/locations/${this.state.otherServiceAreaCode}`}>take a look?</a>
                        </span>
                    </div>
                    : null}

                {/* Error message */}
                {this.state.errorOn ?
                    <div className="address-form__error-wrapper--mobile">
                        <span className="address-form__error js-address-form-error">
                            {this.state.errorMessage}
                        </span>
                        <button onClick={this.clearFilters.bind(this)} className="-btn-pill address-form__clear-filters">Clear filters</button>
                    </div>
                    : null}
            </div>
        )
    }


    /**
     * Creates the office list elements
     * @returns {Object} List of offices on React
     */
    loadOffices() {
        let officeStructure = this.state.officesData.map(el => {
            const styleElement = {
                backgroundImage: `url(${el.image_url})`
            }
            const officeUrl = `/locations/${el.service_area_code}/${el.slug}`

            return (
                <li className="office-list__item" data-slug={el.slug} key={el.slug}
                    onMouseEnter={this.listItemHoverHandler.bind(this)}>
                    <div className="office-list__row row row-xs--middle col-md-5">
                        <div className="col-xs-12 col-md-2 office-list__image-wrapper">
                            <a href={officeUrl}>
                                <div className="office-list__img js-lazy-load" style={styleElement} />
                            </a>
                        </div>

                        <div className="col-md-3 office-list__info-wrapper">
                            <a href={officeUrl} className="office-list__name-anchor">
                                <h2 className="office-list__name">{el.title}</h2>
                            </a>

                            {el.hours.match(/^closed every day|^temporarily closed/ig) ?
                                <div>
                                    <p className="office-list__temp-closed-tag tag">
                                        <svg
                                            className="office-info__closed-icon" xmlns="http://www.w3.org/2000/svg"
                                            width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <circle cx="8" cy="8" r="7.1" stroke="#004D49" strokeWidth="1.8"/>
                                            <line
                                                x1="2.70964" y1="2.05201" x2="13.3328" y2="12.6752" stroke="#004D49"
                                                strokeWidth="1.8"/>
                                        </svg>
                                        Temporarily closed
                                    </p>
                                </div>
                                : null}
                            <div>
                                <p className="office-list__address">
                                    <span>{el.address1} </span>
                                    <span>{el.address2}</span>
                                </p>

                                <p className="office-list__address">
                                    <span>{el.city}, {el.state} </span>
                                    <span>{el.zip}</span>
                                </p>

                                <a href={officeUrl} className="office-list__anchor">
                                    View Location
                                </a>

                                { el.sees_seniors ?
                                    <div>
                                        <p className="office-list__peds-tag tag">
                                            Sees ages 65+
                                        </p>
                                    </div>
                                    : null }
                                { el.pediatric_services === 'True' ?
                                    <div>
                                        <p className="office-list__peds-tag tag">
                                            Sees all ages
                                        </p>
                                    </div>
                                    : null }
                            </div>
                        </div>
                    </div>
                </li>
            )
        })

        return officeStructure
    }


    /**
     * Show the error message on the map
     * @argument {String} errorType The type of error to be displayed (based on the error object)
     */
    displayError(errorType) {
        this.setState({
            errorOn: true,
            errorMessage: errorType
        })
    }


    /**
     * Calculate the zoom level of the map
     * @param {Number} mapLatitude
     * @param {Number} maxDistance
     * @returns {Number} Zoom level for the map
     */
    getCenteredZoomLevel(mapLatitude, maxDistance) {
        const zoomLevelMap = []
        const mapDimension = Math.min(this.mapWrapper.clientHeight, this.mapWrapper.clientWidth)

        for (let i = 0; i < 20; i++) {
            const metersPerPixel = 156543.03392 * Math.cos(mapLatitude * Math.PI / 180) / Math.pow(2, i)
            const mapScale = (mapDimension * metersPerPixel) * 0.001
            zoomLevelMap.push({
                level: i,
                metersPerPixel: metersPerPixel,
                kilometersPerPixel: metersPerPixel * 0.001,
                mapScale: mapScale
            })
        }

        const filtered = zoomLevelMap.filter(level => maxDistance < (level.mapScale / 2))
        return filtered[filtered.length - 1].level
    }


    /**
     * Set markers from offices on the map
     */
    setMarkers(google, markers) {
        markers.forEach(marker => {
            const position = {
                lat: marker.latitude,
                lng: marker.longitude
            }

            const markerInstance = new google.maps.Marker(
                {
                    position: position, map: this.map,
                    icon: marker.sees_seniors ? GLOBAL_CONSTANTS.MAP_ICONS.SENIORS_OFFICE : GLOBAL_CONSTANTS.MAP_ICONS.OFFICE
                }
            )

            markerInstance.addListener('click', (e) => {
                this.markerClickHandler.call(this, e, marker)
            })

            this.markers.push({
                markerInstance: markerInstance,
                slug: marker.slug,
                seesSeniors: marker.sees_seniors
            })
        })
    }


    /**
     * Handle list item hover
     * @param {Object} event
     */
    listItemHoverHandler(event) {
        this.markers.forEach(marker => {
            const isActive = marker.slug === event.target.dataset.slug
            let icon = isActive ? GLOBAL_CONSTANTS.MAP_ICONS.OFFICE_ACTIVE : GLOBAL_CONSTANTS.MAP_ICONS.OFFICE
            icon = marker.seesSeniors ? GLOBAL_CONSTANTS.MAP_ICONS.SENIORS_OFFICE : icon
            const zIndex = isActive ? 100 : -1

            marker.markerInstance.setIcon(icon)
            marker.markerInstance.setZIndex(zIndex)
        })
    }


    /**
     * Handle the click on a map marker
     * @param {Object} event
     * @param {Object} marker Google maps marker
     */
    markerClickHandler(event, marker) {
        const listOffices = Array.from(document.getElementsByClassName('office-list__item'))
        const addressForm = document.querySelector('.js-address-form')
        listOffices.forEach(office => {
            office.classList.remove('-active')
        })
        const office = listOffices.filter(office => {
            return office.dataset.slug === marker.slug
        })


        const currentMarker = this.markers.filter(m => {
            m.markerInstance.setZIndex(-1)
            return m.slug === marker.slug
        })

        currentMarker[0].markerInstance.setZIndex(100)

        let el, offset = null

        if (isStandardTablet()) {
            el = window
            offset = office[0].offsetTop + addressForm.offsetParent.offsetTop - this.navEl.clientHeight
        } else {
            el = this.wrapper.querySelector(CLASSES.OFFICE_LIST)
            offset = office[0].offsetTop - addressForm.parentElement.clientHeight
        }
        office[0].classList.add('-active')
        el.scroll({
            top: offset,
            left: 0,
            behavior: 'smooth'
        })
    }


    /**
     * Toggle Map view style
     */
    toggleMapViewHandler() {

        this.setState({
            mapOn: !this.state.mapOn
        })

        // TODO: Animation pending for the map transition
    }


    /**
     * Calculate the distance to the office from my location
     */
    calculateDistance(locationOne, locationTwo) {
        const distance = this.google.maps.geometry.spherical.computeDistanceBetween(locationOne, locationTwo)

        // Converting to KM
        return distance * 0.001
    }


    /*
     * Calculates office distances from a provided lat/lng point
     */
    calculateOfficeDistances(location) {
        const distanceList = []

        this.state.officesData.forEach(office => {
            const distanceFromServiceArea = this.calculateDistance(
                location,
                new this.google.maps.LatLng({
                    lat: office.latitude,
                    lng: office.longitude
                })
            )
            distanceList.push(distanceFromServiceArea)
        })

        return distanceList
    }


    /*
     * Maps office nodes to our Office data returned from API call.
     */
    mapDistanceToOffices(distanceList) {
        const officeList = []

        this.state.officesData.forEach((office, i) => {
            officeList.push({
                ...office,
                offsetDistance: distanceList[i],
                node: find(this.officeListItems, (i) => i.dataset.slug === office.slug)
            })
        })

        return officeList
    }


    /*
     * Pins user location and positions map to encompass provided location instance.
     */
    setUserPinLocation(locationInstance, centerPostion, zoomLevel) {
        // const selectedMarker = this.markers.filter(marker => {
        //     return marker.slug === locationInstance.slug
        // })

        // this.onMarkerClick(null, selectedMarker[0])
        this.map.setCenter(centerPostion)
        this.map.setZoom(zoomLevel)

        if (this.userAddress) {
            this.userAddress.setMap(null)
        }

        this.userAddress = new this.google.maps.Marker({
            map: this.map,
            position: centerPostion,
            icon: GLOBAL_CONSTANTS.MAP_ICONS.USER
        })
    }


    /**
     * Success when geolocalizing the user
    */
    geoSuccess(position) {
        // TODO: Add animation when load is complete (back to icon)
        const userLocation = new this.google.maps.LatLng({
            lat: position.coords.latitude,
            lng: position.coords.longitude
        })

        const distanceFromServiceArea = this.calculateDistance(
            userLocation,
            new this.google.maps.LatLng(this.serviceAreaPosition)
        )

        if (distanceFromServiceArea < 80) {
            const distanceList = this.calculateOfficeDistances(userLocation)
            const mappedList = this.mapDistanceToOffices(distanceList)
            const orderedList = orderBy(mappedList, 'offsetDistance', 'asc')

            this.setState({
                officesData: orderedList
            })

            const centerPostion = userLocation
            const zoomLevel = this.getCenteredZoomLevel(userLocation.lat(), orderedList[0].offsetDistance)
            this.setUserPinLocation(orderedList[0], centerPostion, zoomLevel)


            this.geocoder.geocode({'latLng': userLocation}, (results, status) => {
                if (status === 'OK') {
                    this.setState({
                        formattedAddress: results[0].formatted_address
                    })
                } else {
                    this.errorEl.innerHTML = ERRORS.GENERAL
                    this.errorEl.classList.add(GLOBAL_CONSTANTS.CLASSES.VISIBLE)
                    Emitter.emit('error', {
                        message: `Reverse Geocode was not successful for the following reason: ${status}`,
                        stacktrace: []
                    })
                }
            })

            this.setState({errorOn: false})
        } else {
            this.displayError(ERRORS.DISTANCE)
        }
    }


    /**
     * Find the location searched in another service area
     */
    findInAnotherServiceArea (place) {
        const serviceAreas = [...this.serviceAreas]
        let closestServiceArea = []

        serviceAreas.forEach(sa => {
            if (sa.latitude) {
                const serviceAreaPosition = {
                    lat: sa.latitude,
                    lng: sa.longitude
                }

                const distance = this.calculateDistance(
                    place.geometry.location,
                    new this.google.maps.LatLng(serviceAreaPosition)
                )

                const saWithDistance = {
                    code: sa.code,
                    name: sa.name,
                    distance: distance
                }

                if ((distance < 80 && closestServiceArea.length === 0) || distance < closestServiceArea.distance) {
                    closestServiceArea = saWithDistance
                }
            }
        })

        if (closestServiceArea) {
            return closestServiceArea
        } else {
            return false
        }
    }


    /**
     * Handle the submit enter when searching for an address
     * @param {Object} e Event from submit
     */
    handleSubmit (event) {
        event.preventDefault()

        const formData = new FormData(event.target)

        this.geocoder.geocode({'address': formData.get('address')}, (results, status) => {
            if (status === 'OK') {
                // Hide error if any
                this.setState({errorOn: false})

                /*
                ** Calculate the distance from the service area lat/lng to  determine if we set the coord
                */
                const distanceFromServiceArea = this.calculateDistance(
                    results[0].geometry.location,
                    new this.google.maps.LatLng(this.serviceAreaPosition)
                )


                // 80 is max distance in km
                if (distanceFromServiceArea < 80) {

                    /*
                     * Calculate are office distances (km) from the address we are searching
                     * set the map to address and calculate and set our centered zoom level
                     */
                    const officeDistanceList = this.calculateOfficeDistances(results[0].geometry.location)

                    /*
                     * Rerender office list in ascending order from distance to address
                     */
                    const mappedList = this.mapDistanceToOffices(officeDistanceList)
                    const orderedList = orderBy(mappedList, 'offsetDistance', 'asc')


                    this.setState({
                        officesData: orderedList
                    })

                    /*
                     * Get closest office from search address, find raw data for instance,
                     * and compare against our generated marker list. Manually call the marker click callback.
                     */
                    const minOfficeDistance = Math.min(...officeDistanceList)
                    const minOfficeInstance = this.state.officesData[officeDistanceList.indexOf(minOfficeDistance)]

                    /*
                     * Calculate the map center position, zoom level, and set the map.
                     */
                    const centerPostion = results[0].geometry.location
                    const zoomLevel = this.getCenteredZoomLevel(results[0].geometry.location.lat(), minOfficeDistance)
                    this.setUserPinLocation(minOfficeInstance, centerPostion, zoomLevel)

                } else {
                    const anotherServiceArea = this.findInAnotherServiceArea(results[0])

                    if (anotherServiceArea) {
                        this.setState({
                            otherServiceAreaName: anotherServiceArea.name,
                            otherServiceAreaCode: anotherServiceArea.code
                        })
                    } else {
                        this.displayError(ERRORS.DISTANCE)
                    }
                }

            } else {
                Emitter.emit('error', {
                    message: `Geocode was not successful for the following reason: ${status}`,
                    stacktrace: []
                })
            }
        })
    }


    /**
     * Generate Selection Model
     */
    generateSelectModel() {
        let checkboxes = {}

        const filterNameMap = {
            'sees_seniors': 'Sees ages 65+',
            'pediatric_services': 'Sees all ages'
        }
        const filterDescriptionMap = {
            'sees_seniors': 'Primary care offices that see ages 65+ on Medicare',
            'pediatric_services': 'Family practice offices that treat both kids and adults'
        }

        Object.keys(this.state.filters).forEach(filterKey => {
            let formattedName, filterDescription
            if (filterKey in filterNameMap && filterKey in filterDescriptionMap) {
                formattedName = filterNameMap[filterKey]
                filterDescription = filterDescriptionMap[filterKey]
            } else {
                formattedName = filterKey.split('_').join(' ').replace(/\b\w/g, l => l.toUpperCase())
            }

            checkboxes[filterKey] = {
                id: filterKey,
                label: formattedName,
                selected: this.state.filters[filterKey],
                description: filterDescription
            }
        })

        const selects = {
            filters: {
                name: 'Filters',
                type: 'checkboxes',
                checkboxes: checkboxes
            }
        }

        return selects
    }


    /**
     * React Component Did Mount
     */
    componentDidMount() {
        this.initializeMap()

        axios.get('/api/service_areas/')
            .then(res => {
                this.serviceAreas = res.data
            })
    }


    /**
     * Render React component
     */
    render() {
        this.setURL()
        const mapOn = this.state.mapOn

        return (
            <div className={`area-office-map js-map-section-wrapper row row-xs--start ${mapOn ? '-active' : ''}`}>
                {/* Filters and Searchbar */}
                <div className="col-xs-12 area-office-map__search-wrapper">
                    <div>
                        {this.loadFilters()}
                    </div>

                    <div className="col-xs-12 area-office-map__search-wrapper">
                        <div className="area-office-map__filter-container row row-xs--between">
                            <div className="col-xs-12 col-md-6">
                                {`Viewing ${this.state.officesData.length} results in ${this.props.data.name}`}
                            </div>

                            <div className="col-xs-12 col-md-6 area-office-map__toggle-wrapper">
                                Map
                                <button aria-label="Toggle map view"
                                    className={`area-office-map__toggle ${mapOn ? '-active' : ''}`}
                                    onClick={debounce(this.toggleMapViewHandler.bind(this))}>
                                    <span className="-off-screen">
                                        Toggle map view
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Map and Offices List */}
                <div className="row area-office-map__map-list-container">
                    {/* List */}
                    <div className="col-xs-12 col-md-5 area-office-map__office-wrapper">
                        {/* Found offices in another service area */}
                        {this.state.otherServiceAreaName.length ?
                            <div className="address-form__error-wrapper--desktop">
                                <span className="address-form__error js-address-form-error">{`
                                    Hmm. We found offices closer to the location you’ve entered in our ${this.state.otherServiceAreaName} region. Do you want to `}
                                <a className="address-form__link" href={`/locations/${this.state.otherServiceAreaCode}`}>take a look?</a>
                                </span>
                            </div>
                            : null}

                        {/* Error message */}
                        {this.state.errorOn ?
                            <div className="address-form__error-wrapper--desktop">
                                <span className="address-form__error js-address-form-error">
                                    {this.state.errorMessage}
                                </span>
                                <button onClick={this.clearFilters.bind(this)} className="-btn-pill address-form__clear-filters">Clear filters</button>
                            </div>
                            : null}

                        <ul className="office-list">
                            {this.state.officesData ? this.loadOffices() : null}
                        </ul>
                    </div>

                    {/* Map */}
                    <div className="col-xs-12 col-md-7 area-office-map__map-wrapper">
                        <div className="area-office-map__legend">
                            <img className="area-office-map__legend-image" src={GLOBAL_CONSTANTS.MAP_LEGEND} alt=''/>
                        </div>
                        <div className="map map--area-office js-map-refactor"/>
                    </div>
                </div>
            </div>
        )
    }
}

export default class MountOfficesMap {
    constructor(el) {
        this.el = el
        this.fetchLocations()
    }

    fetchLocations(req = '/api/locations/') {
        let params = {
            code: this.el.dataset.code
        }

        axios.get(req, {
            params: params
        })
            .then(res => {
                const data = JSON.parse(res.data)

                this.renderComponent(data)
            })
            .catch(err => {
                console.error(err)
            })
    }

    renderComponent(officesData) {
        this.component = render(
            <OfficesMap
                element={this.el}
                data={officesData}
                serviceAreaCode={this.el.dataset.code}
            />,
            this.el
        )
    }
}

export const OfficesMapComponent = {
    'name': 'Offices Map',
    'class': CLASSES.COMPONENT,
    'Source': MountOfficesMap
}

import Emitter from 'utils/emitter'
import { throttle } from 'throttle-debounce'
import { GLOBAL_CONSTANTS } from 'utils/constants'

/**
 * Creates show/hide functionality for accordion components
 */

const ATTRIBUTES = {
    ACCORDION_EXPANDED: 'aria-expanded',
    BODY_HIDDEN: 'aria-hidden'
}

const SELECTORS = {
    COMPONENT: '.js-accordion',
    ACCORDION_ITEM: '.js-accordion-item',
    ACCORDION_TOGGLE: '.js-accordion-toggle',
    ACCORDION_ITEM_BODY_WRAPPER: '.js-accordion-body-wrapper',
    ACCORDION_ITEM_BODY: '.js-accordion-body'
}

export default class Accordion {
    /**
     * @desc Set up accordions with elements and bind events.
     * @param {HTMLElement} el - Container element for accordion
     *
     */

    constructor(element) {
        this.element = element
        this.accordionItemArr = Array.from(this.element.querySelectorAll(SELECTORS.ACCORDION_ITEM))
        this.itemObjArr = []
        this.initialize()
    }

    initialize() {
        this.bindAccordionItems()
        this.registerEvents()
    }

    /**
     * @desc Create an object for each accordion item
     */
    bindAccordionItems() {
        this.accordionItemArr.forEach((item) => {
            const itemObj = {
                element: item,
                toggleButton: item.querySelector(SELECTORS.ACCORDION_TOGGLE),
                body: item.querySelector(SELECTORS.ACCORDION_ITEM_BODY),
                bodyWrapper: item.querySelector(SELECTORS.ACCORDION_ITEM_BODY_WRAPPER)
            }

            this.itemObjArr.push(itemObj)

            itemObj.toggleButton.addEventListener('click', (event) => {
                this.toggleItem(event, itemObj)
            })
            Emitter.on(GLOBAL_CONSTANTS.EVENTS.CLOSE_MOBILE_MENU, this.closeAllAccordions.bind(this))
        })
    }

    /**
     * @desc Register resize listener with attached throttle
     */
    registerEvents() {
        this.throttleResize = throttle(GLOBAL_CONSTANTS.TIMING.RESIZE_THROTTLE, this.updateAccordionHeight.bind(this))
        Emitter.on(GLOBAL_CONSTANTS.EVENTS.RESIZE, this.throttleResize)
    }

    /**
     * @param  {} item
     * @desc toggle accordion items open and closed
     */
    toggleItem(event, item) {
        event.preventDefault()
        const itemIsActive = item.element.classList.contains(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        const itemBodyHeight = item.body.offsetHeight

        if (itemIsActive) {
            item.toggleButton.setAttribute(ATTRIBUTES.ACCORDION_EXPANDED, false)
            item.bodyWrapper.setAttribute(ATTRIBUTES.BODY_HIDDEN, true)
            item.bodyWrapper.style.height = 0
            item.element.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        } else {
            item.toggleButton.setAttribute(ATTRIBUTES.ACCORDION_EXPANDED, true)
            item.bodyWrapper.setAttribute(ATTRIBUTES.BODY_HIDDEN, false)
            item.bodyWrapper.style.height = `${itemBodyHeight}px`
            item.element.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        }

    }

    closeAllAccordions() {
        this.itemObjArr.forEach((item) => {
            item.toggleButton.setAttribute(ATTRIBUTES.ACCORDION_EXPANDED, false)
            item.bodyWrapper.setAttribute(ATTRIBUTES.BODY_HIDDEN, true)
            item.bodyWrapper.style.height = 0
            item.element.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        })
    }

    /**
     * @desc update accordion height on resize
     */
    updateAccordionHeight() {
        this.itemObjArr.forEach(item => {
            const itemIsActive = item.element.classList.contains(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
            const itemBodyHeight = item.body.offsetHeight

            if (itemIsActive) {
                item.bodyWrapper.style.height = `${itemBodyHeight}px`
            }
        })
    }
}

/**
 * @desc Test component definition used in module-loader
 */

export const AccordionComponent = {
    'name': 'Accordion',
    'class': SELECTORS.COMPONENT,
    'Source': Accordion
}

import React, {Component} from 'react'
import classNames from 'classnames'

export default class SelectMenu extends Component {

    constructor(props) {
        super(props)
        this.toggleSelect = this.toggleSelect.bind(this)
        this.onSelectChange = this.onSelectChange.bind(this)
        this.setSelectedState = this.setSelectedState.bind(this)
        this.ref = React.createRef()
    }

    state = {
        active: false,
        selected: this.setSelectedState()
    }

    componentWillReceiveProps(props) {
        if (this.props.activeSelectRef) {
            if (this.props.activeSelectRef !== props.activeSelectRef && this.state.active) {
                this.setState({active: !this.state.active})
            }
        }
    }

    setSelectedState() {
        var selectedState = {}
        if (this.props.select.type === 'select') {
            return this.props.select.selectedOption
        } else {
            Object.keys(this.props.select.checkboxes).forEach((key) => {
                selectedState[key] = this.props.select.checkboxes[key].selected
            })
            return selectedState
        }
    }

    toggleSelect(e) {
        if (e) { e.preventDefault() }

        this.setState(() => {
            return {
                active: !this.state.active,
                selected: this.setSelectedState()
            }
        })
        this.props.onSelectTrigger(this.ref)
    }

    onSelectChange(data) {
        if (this.props.select.type === 'select') {
            this.setState({ selected: data })
        } else {
            const selectedState = Object.assign({}, this.state.selected)
            selectedState[Object.keys(data)[0]] = data[Object.keys(data)[0]]
            this.setState({ selected: selectedState})
        }

    }

    generateSelect(select) {
        const options = select.options
        return (
            Object.keys(options).map((key) => {
                return (
                    <li className="form-select-menu-list-item" key={key}>
                        <label className="form-select-menu-select-wrapper" htmlFor={key}>
                            <input
                                className="-off-screen"
                                type="radio"
                                id={ key }
                                name={ select.id }
                                value={ key }
                                checked={ this.state.selected === key }
                                onChange={ e => { this.onSelectChange(e.target.value) }}
                            />
                            <span className="form-select-menu-select-label">{ options[key].label }</span>
                        </label>
                    </li>
                )
            })
        )
    }

    generateChecks(item) {
        const checkboxes = item.checkboxes
        return (
            Object.keys(checkboxes).map((key) => {
                return (
                    <li className="form-select-menu-list-item" key={key}>
                        <label className="form-select-menu-select-wrapper" htmlFor={key}>
                            <input
                                className="-off-screen"
                                type="checkbox"
                                id={key}
                                name={key}
                                checked={this.state.selected[key]}
                                onChange={() => { this.onSelectChange({[key]: !this.state.selected[key]}) }}
                            />
                            <span className="form-select-menu-select-label">{checkboxes[key].label}</span>
                            {checkboxes[key].description && <p className="form-select-menu-select-description">{checkboxes[key].description}</p>}
                        </label>
                    </li>
                )
            })
        )
    }

    generateButtonLabel(select) {
        if (select.type === 'checkboxes') {
            const checkboxes = select.checkboxes
            let labels = []
            Object.keys(checkboxes).forEach((key) => {
                if (checkboxes[key].selected) {
                    labels.push(checkboxes[key].label)
                }
            })
            return labels.length > 0 ? labels.join(', ') : select.name
        } else {
            return select.options[select.selectedOption].label
        }
    }

    isFilterApplied(select) {
        for (let checkbox in select.checkboxes) {
            if (select.checkboxes[checkbox].selected) { return true }
        }
        return false
    }

    render() {
        const {
            alignRight,
            select
        } = this.props

        const {
            active
        } = this.state

        return (
            <div
                ref={this.ref}
                className={classNames('form-select-wrapper', {
                    '-active': active,
                    '-right': alignRight,
                    '-columns': select.columns,
                    'col-md--hide': select.mobile_only
                })}
            >
                <button
                    className={classNames('form-select-wrapper-button', {
                        '-btn-pill--active-filter': select.type === 'checkboxes' && this.isFilterApplied(select),
                        '-btn-pill--inactive-filter': select.type === 'checkboxes' && !this.isFilterApplied(select),
                        '-btn-pill--select': select.type === 'select'
                    })}
                    onClick={this.toggleSelect}
                >
                    <span className="form-select-wrapper-button-inner-wrapper">{this.generateButtonLabel(select)}</span>
                    <i className="form-select-wrapper-arrow" />
                </button>
                <div className="form-select-menu">
                    <form className="form-select-menu-form" onSubmit={e => { this.props.applyFilter(e, this.toggleSelect) }}>
                        <ul className="form-select-menu-list">
                            {select.type === 'select' ? this.generateSelect(select) : this.generateChecks(select)}
                        </ul>
                        <div className="form-select-menu-button-wrapper">
                            <a href="#" className="form-select-menu-cancel" onClick={this.toggleSelect}>Cancel</a>
                            <button className="-btn-pill form-select-menu-button" type="submit">Apply</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}
import React, {Component} from 'react'
import classNames from 'classnames'
import { serializeForm } from 'utils/serializeForm'
import Accordion from 'base/components/Accordion/Accordion'
import SelectMenu from 'base/components/SelectMenu'

export default class FilterGroup extends Component {

    constructor(props) {
        super(props)
        this.toggleModal = this.toggleModal.bind(this)
        this.applyFilter = this.applyFilter.bind(this)
        this.onSelectTrigger = this.onSelectTrigger.bind(this)
    }

    state = {
        modalActive: false,
        activeSelectRef: null
    }

    onSelectTrigger(ref) {
        this.setState({activeSelectRef: ref})
    }

    applyFilter(event, callback) {
        event.preventDefault()
        let data = serializeForm(event.target)

        /**
         * NOTE:
         * Need to validate if the service area is changing to preserve
         * the filters selections and pass them along with the new service
         * area.
         */
        if (data.service_area) {
            const selected = {...this.props.filters}

            Object.keys(selected).forEach(key => {
                if (key !== 'service_area') {
                    data[key] = selected[key]
                }
            })
        }

        this.props.applySelect(data, this.state.inputList)

        if (callback) {
            callback()
        }
    }

    toggleModal(event) {
        if (event) { event.preventDefault() }
        this.setState({modalActive: !this.state.modalActive})
    }

    render() {
        const {
            headline,
            selects
        } = this.props

        const {
            modalActive,
            activeSelectRef
        } = this.state

        return (
            <div className="filter-group">
                <button type="button" className="-btn-pill--quaternary filter-group-mobile-cta" onClick={this.toggleModal}>{ headline }</button>
                <div className="filter-group-wrapper">
                    {Object.keys(selects).map((key, i) => {
                        const select = selects[key]
                        return (
                            <SelectMenu
                                key={i}
                                alignRight
                                select={select}
                                onSelectTrigger={this.onSelectTrigger}
                                activeSelectRef={activeSelectRef}
                                applyFilter={this.applyFilter}
                            />
                        )
                    })}
                </div>
                {this.state.modalActive &&
                    <div className={classNames('filter-group-mobile-wrapper', {'-active': modalActive})}>
                        <a className="filter-group-mobile-close" href="#" onClick={this.toggleModal}>
                            <span className="-visually-hidden">Close</span>
                        </a>
                        <form onSubmit={e => {this.applyFilter(e, this.toggleModal)}}>
                            <Accordion
                                headline={headline}
                                selects={selects}
                                applyFilter={this.applyFilter}
                            />
                            <div className="filter-group-mobile-submit-outer-wrapper">
                                <div className="filter-group-mobile-submit-inner-wrapper">
                                    <button type="submit" className="-btn-pill--primary filter-group-mobile-submit">Apply</button>
                                    <a href="#" className="filter-group-mobile-cancel" onClick={this.toggleModal}>Cancel</a>
                                </div>
                            </div>
                        </form>
                    </div>
                }
            </div>
        )
    }
}


const CLASSES = {
    COMPONENT: '.rotating-services'
}

export default class RotatingServicesClass {
    constructor() {
        this.container = document.querySelector(CLASSES.COMPONENT)
        const isRandom = String(document.querySelector('.rotating-services').dataset.rsRandomWords).toLowerCase()
        const wordsArray = document.getElementsByClassName('rotating-services__headline--highlight')
        const rotationSpeed = (parseFloat(document.querySelector(CLASSES.COMPONENT).dataset.rsRotationSpeed) * 1000)
        let currentWord = 0

        function getRandom(list) {
            return Math.floor((Math.random() * list.length))
        }

        function _removeClasses(els) {
            for (let i = 0; i < els.length; i++) {
                els[i].classList.remove('is-visible')
                els[i].classList.add('is-hidden')
            }
        }

        function changeWords() {

            if (isRandom === 'true') {

                _removeClasses(wordsArray)
                let cw = wordsArray[getRandom(wordsArray)]
                cw.classList.remove('is-hidden')
                cw.classList.add('is-visible')

            } else {
                let cw = wordsArray[currentWord]
                let nw = (currentWord === wordsArray.length - 1 ? wordsArray[0] : wordsArray[currentWord + 1])

                cw.classList.remove('is-visible')
                cw.classList.add('is-hidden')
                nw.classList.add('is-visible')
                nw.classList.remove('is-hidden')

                currentWord = (currentWord === wordsArray.length - 1) ? 0 : currentWord + 1
            }
        }

        setInterval(changeWords, rotationSpeed)
    }
}

export const RotatingServices = {
    'name': 'Rotating Services',
    'class': CLASSES.COMPONENT,
    'Source': RotatingServicesClass
}

import BaseEntryClass from 'base/BaseEntryClass'
import isLoaded from 'utils/isLoaded'
import SentryWrapper from 'utils/SentryWrapper'
import LaunchDarkly from 'utils/LaunchDarkly'
import SetUtmCookies from 'utils/SetUtmCookies'
import SetUtmParams from 'utils/SetUtmParams'
import LoadDiscountTerms from 'utils/LoadDiscountTerms'
import LazyLoad from 'utils/LazyLoad'

class App extends BaseEntryClass {
    constructor() {
        super()
        this.SentryWrapper = new SentryWrapper()
        this.LazyLoad = new LazyLoad()
        this.SetUtmCookies = new SetUtmCookies()
        this.LaunchDarkly = new LaunchDarkly()
        this.SetUtmParams = new SetUtmParams()
        this.LoadDiscountTerms = new LoadDiscountTerms()
    }
}

isLoaded(App)

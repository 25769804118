import React, {Component} from 'react'
import AccordionItem from 'base/components/Accordion/AccordionItem'

export default class Accordion extends Component {

    generateSelect(select) {
        return (
            select.options.map((option) => {
                return (
                    <li className="form-select-menu-list-item" key={option.value}>
                        <label className="form-select-menu-select-wrapper" htmlFor={ option.value }>
                            <input
                                className="-off-screen"
                                type="radio"
                                id={ option.value }
                                name={ option.id }
                                value={ option.value }
                            />
                            <span className="form-select-menu-select-label">{ option.label }</span>
                        </label>
                    </li>
                )
            })
        )
    }

    generateCheck(item) {
        return (
            <li className="form-select-menu-list-item">
                <label className="form-select-menu-select-wrapper" htmlFor={ item.id }>
                    <input
                        className="-off-screen"
                        type="checkbox"
                        id={ item.id }
                        name={ item.id }
                        value={ item.value }
                    />
                    <span className="form-select-menu-select-label">{ item.label }</span>
                </label>
            </li>
        )
    }

    render() {
        const {
            selects,
            headline
        } = this.props

        return (
            <div className="accordion row">
                <h2 className="accordion__headline col-xs--clear">{ headline }</h2>
                <ol className="accordion__list col-xs-12 col-md-10 col-md-offset-1" data-aria-accordion>
                    {Object.keys(selects).map((key, i) => {
                        const select = selects[key]
                        return (
                            <AccordionItem
                                key={i}
                                select={select}
                            />
                        )
                    })}
                </ol>
            </div>
        )
    }
}
import { allowScroll } from 'utils/scrollUtil'

const CLASSES = {
    HERO: '.page-hero',
    COMPONENT: '.page-hero__scroll',
    CTA: '.page-hero__scroll-wrapper'
}

export default class ScrollDown {
    constructor(element) {
        this.el = element
        this.button = this.el.querySelector(CLASSES.CTA)
        this.scrollPos = document.querySelector(CLASSES.HERO).offsetHeight

        this.initialize()
    }

    /**
     * InitializeEvents
     */
    initialize() {
        this.registerEvents()
    }


    /**
     * Register Events
     */
    registerEvents() {
        this.button.addEventListener('click', this.scrollToBottom.bind(this))
    }


    /**
     * Scroll to bottom
     */
    scrollToBottom() {
        const options = {
            top: this.scrollPos,
            left: 0,
            behavior: 'smooth'
        }

        allowScroll(0, options)
    }
}


/**
 * @desc Test component definition used in module-loader
 */

export const ScrollDownComponent = {
    'name': 'Scroll Down',
    'class': CLASSES.COMPONENT,
    'Source': ScrollDown
}
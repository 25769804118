import React, {Component} from 'react'
import classNames from 'classnames'

export default class AccordionItem extends Component {

    constructor(props) {
        super(props)
        this.toggleAccordionItem = this.toggleAccordionItem.bind(this)
        this.onSelectChange = this.onSelectChange.bind(this)
        this.setSelectedState = this.setSelectedState.bind(this)
    }

    state = {
        active: false,
        selected: this.setSelectedState()
    }

    setSelectedState() {
        var selectedState = {}
        if (this.props.select.type === 'select') {
            return this.props.select.selectedOption
        } else {
            Object.keys(this.props.select.checkboxes).forEach((key) => {
                selectedState[key] = this.props.select.checkboxes[key].selected
            })
            return selectedState
        }
    }

    onSelectChange(data) {
        if (this.props.select.type === 'select') {
            this.setState({ selected: data })
        } else {
            const selectedState = Object.assign({}, this.state.selected)
            selectedState[Object.keys(data)[0]] = data[Object.keys(data)[0]]
            this.setState({ selected: selectedState })
        }
    }

    toggleAccordionItem(event) {
        event.stopPropagation()
        this.setState({active: !this.state.active})
    }

    generateSelect(select) {
        const options = select.options
        return (
            Object.keys(options).map((key) => {
                return (
                    <li className="form-select-menu-list-item" key={key}>
                        <label className="form-select-menu-select-wrapper" htmlFor={ `${key}-mobile` }>
                            <input
                                className="-off-screen"
                                type="radio"
                                id={ `${key}-mobile` }
                                name={ select.id }
                                value={ key }
                                checked={ this.state.selected === key }
                                onChange={ e => { this.onSelectChange(e.target.value) }}
                            />
                            <span className="form-select-menu-select-label">{ options[key].label }</span>
                        </label>
                    </li>
                )
            })
        )
    }

    generateChecks(item) {
        const checkboxes = item.checkboxes
        return (
            Object.keys(checkboxes).map((key) => {
                return (
                    <li className="form-select-menu-list-item" key={key}>
                        <label className="form-select-menu-select-wrapper" htmlFor={`${key}-mobile`}>
                            <input
                                className="-off-screen"
                                type="checkbox"
                                id={`${key}-mobile`}
                                name={key}
                                checked={this.state.selected[key]}
                                onChange={() => { this.onSelectChange({[key]: !this.state.selected[key]}) }}
                            />
                            <span className="form-select-menu-select-label">{checkboxes[key].label}</span>
                        </label>
                    </li>
                )
            })
        )
    }

    render() {
        const {
            select
        } = this.props

        const {
            active
        } = this.state

        return (
            <li
                className={classNames('accordion__item', {'-active': active})}
            >
                <h3 className="accordion__item-headline">
                    <button
                        className="accordion__item-button"
                        aria-expanded={active ? 'true' : 'false'}
                        type="button"
                        onClick={this.toggleAccordionItem}
                    >
                        { select.name }
                    </button>
                    <span className="accordion__icon-wrapper">
                        <span className="accordion__icon-line accordion__icon-line--horizontal" />
                        <span className="accordion__icon-line accordion__icon-line--vertical" />
                    </span>
                </h3>
                <div className="accordion__item-body js-accordion-body-wrapper" aria-hidden="true">
                    <div className="accordion__item-body--inner-wrapper">
                        <ul className="form-select-menu-list">
                            {select.type === 'select' ? this.generateSelect(select) : this.generateChecks(select)}
                        </ul>
                    </div>
                </div>
            </li>
        )
    }
}
import { AccordionComponent } from 'components/Accordion'
import { CareersFilterComponent } from 'components/CareersFilter'
import { CarouselComponent } from 'components/Carousel'
import { EloquaFormValidationComponent } from 'components/EloquaFormValidation'
import { FallingSnow } from 'components/FallingSnow'
import { FormSelectComponent } from 'components/FormSelect'
import { GreenhouseiFrameComponent } from 'components/GreenhouseiFrame'
import { InsuranceFinderComponent } from 'components/InsuranceFinder/InsuranceFinder'
import { InsuranceTable } from 'components/InsuranceTable'
import { NavComponent } from 'components/Nav'
import { OfficesMapComponent } from 'components/OfficesMap/OfficesMap'
import { OfficesSearchComponent } from 'components/OfficesMap/OfficesSearch'
import { ProvidersListComponent } from 'components/ProvidersList/ProvidersList'
import { ScrollDownComponent } from 'components/ScrollDown'
import { ScrollTargetComponent } from 'components/ScrollTarget'
import { ServiceAreaPreselectRegistrationComponent } from 'components/ServiceAreaPreselectRegistrationComponent'
import { SetEloquaUtmFormFieldsComponent } from 'components/SetEloquaUtmFormFields'
import { TimelineComponent } from 'components/Timeline'
import { ExpandableEmailBannerSectionComponent } from 'components/ExpandableEmailBannerSection'
import { ProvidersGridExpandComponent } from 'components/ProvidersGridExpand'
import { ProviderBookingComponent } from 'components/ProviderBooking/ProviderBooking'
import { ProviderAppointmentsListComponent } from 'components/ProviderAppointmentsList/ProviderAppointmentsList'
import { OfficeAppointmentsListComponent } from 'components/OfficeAppointmentsList/OfficeAppointmentsList'
import { ToolTipComponent } from 'components/ProviderToolTip/ProviderToolTip'
import { VideoLightboxComponent } from 'components/VideoLightbox/VideoLightbox'
import { RotatingServices } from 'components/RotatingServices'
import { StatsAnimated } from 'components/StatsAnimated'
import { OM4BTotalCostAccordion } from 'components/OM4BTotalCostAccordion'
import { JetsetScrolldown } from 'components/JetsetScrolldown'
import {LiveChatWidgetComponent} from 'components/LiveChatWidget'
import { ServiceAreaSelectComponent } from 'components/ServiceAreaSelect'
import { OfficeHoursComponent } from 'components/OfficeHours/OfficeHours'
import {GTMClickTrackingComponent} from 'components/GTMClickTracking'



// Add module objects alphabetically as needed
// // name = Class Name
// // class = html .class
// // Source = imported module
export const modules = [
    GTMClickTrackingComponent,
    AccordionComponent,
    CareersFilterComponent,
    CarouselComponent,
    EloquaFormValidationComponent,
    ExpandableEmailBannerSectionComponent,
    FallingSnow,
    FormSelectComponent,
    GreenhouseiFrameComponent,
    InsuranceFinderComponent,
    InsuranceTable,
    NavComponent,
    OfficesMapComponent,
    OfficesSearchComponent,
    ProviderAppointmentsListComponent,
    ProviderBookingComponent,
    ProvidersGridExpandComponent,
    ProvidersListComponent,
    ScrollDownComponent,
    ScrollTargetComponent,
    ServiceAreaPreselectRegistrationComponent,
    SetEloquaUtmFormFieldsComponent,
    TimelineComponent,
    ToolTipComponent,
    VideoLightboxComponent,
    ProviderAppointmentsListComponent,
    OfficeAppointmentsListComponent,
    RotatingServices,
    ServiceAreaSelectComponent,
    StatsAnimated,
    OM4BTotalCostAccordion,
    JetsetScrolldown,
    LiveChatWidgetComponent,
    OfficeHoursComponent
]

export default modules

import React from 'react'
import { findDOMNode } from 'react-dom'

export default (WrappedComponent, { threshold = 1.0, once = false }) =>
    class extends React.Component {
    state = {
        visible: false
    }

    componentDidMount () {
        this.startTracking()
    }

    componentWillUnmount () {
        this.stopTracking()
        this.el = null
    }

    startTracking () {
        this.tracker = new IntersectionObserver(this.updateVisibility, {
            root: null,
            rootMargin: '0px',
            threshold: threshold
        })

        if (this.el) {
            this.tracker.observe(this.el)
        }
    }

    stopTracking () {
        if (this.tracker) {
            this.tracker.disconnect()
            this.tracker = null
        }
    }

    setVisible (visible) {
        if (visible !== this.state.visible) {
            this.setState({ visible })

            if (visible && once) {
                this.stopTracking()
            }
        }
    }

    updateVisibility = (entries) => {
        const ratio = entries[0].intersectionRatio

        if (ratio === 0.0) {
            this.setVisible(false)
        } else if (ratio >= threshold) {
            this.setVisible(true)
        }
    }

    updateElement = (component) => {
        const el = findDOMNode(component)

        if (this.tracker) {
            if (this.el && this.el !== el) {
                this.tracker.unobserve(this.el)
            }

            if (el) {
                this.tracker.observe(el)
            }
        }

        this.el = el
    }

    render () {
        return (
            <WrappedComponent
                {...this.props}
                visible={this.state.visible}
                ref={this.updateElement}
            />
        )
    }
    }

import React, {Fragment} from 'react'
import axios from 'axios'
import { render } from 'react-dom'
import moment from 'moment'
import 'moment-timezone'
import { GLOBAL_CONSTANTS } from 'utils/constants'

const CLASSES = {
    COMPONENT: '.js-provider-appointments-list'
}
const MAX_NUMBER_OF_APPOINTMENTS_TO_SHOW = 3

class ProviderAppointmentsList extends React.Component {
    constructor(props) {
        super(props)
        this.wrapper = this.props.element
        const thirtyMinutesFromNow = moment().add(30, 'minutes')
        this.primaryProvider = null
        if (this.props.data && this.props.data.appointments) {
            this.primaryProvider = this.props.data.appointments.provider
            if (this.primaryProvider.appointments && this.primaryProvider.appointments.length) {
                this.primaryProvider.appointments.forEach(a => {
                    a.date = moment.utc(a.startAt).tz(this.getTimezoneString()).format('YYYY-MM-DD')
                })
                this.primaryProvider.appointments = this.primaryProvider.appointments.filter(a => {
                    return moment.utc(a.startAt).isAfter(thirtyMinutesFromNow)
                }).sort((a, b) => {
                    return moment(a['startAt']) > moment(b['startAt']) ? 1 : -1
                })

                if (this.primaryProvider.provider_roles.includes(GLOBAL_CONSTANTS.APPOINTMENTS.PEDIATRIC_ROLE)) {
                    this.primaryProvider.appointments.forEach(a => {
                        a.label = a.duration === GLOBAL_CONSTANTS.APPOINTMENTS.PEDIATRIC_APPOINTMENT_LENGTH ? GLOBAL_CONSTANTS.APPOINTMENTS.PEDIATRIC_LABEL : a.label
                    })
                }
            }

            const providers = this.props.data.appointments.other_providers
            const otherProviders = providers.filter(p => p.id !== this.primaryProvider.id )
            otherProviders.forEach(p => {
                p.appointment = p.appointments.filter(a => {
                    return moment.utc(a.startAt).isAfter(thirtyMinutesFromNow)
                })[0]
            })
            this.officeProviders = otherProviders.filter(p => p.appointment)
        }
    }

    groupBy(xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x)
            return rv
        }, {})
    }

    signupUrl(appointmentInventoryId = null) {
        let signupUrlString = `${GLOBAL_CONSTANTS.SIGNUP_URL}?service_area_id=${this.props.currentServiceAreaId}`
        if (appointmentInventoryId !== null) {
            signupUrlString += `&appointment_inventory_id=${appointmentInventoryId}`
        }
        return signupUrlString
    }

    dateStringHeader(dateString) {
        const dateStringDate = moment(dateString, 'YYYY-MM-DD').startOf('day')
        const today = moment().startOf('day')
        const tomorrow = moment().add(1, 'days').startOf('day')
        if (dateStringDate.isSame(today)) {
            return 'Today'
        } else if (dateStringDate.isSame(tomorrow)) {
            return 'Tomorrow'
        } else {
            return dateStringDate.format('MMM D')
        }
    }

    getTimezoneString() {
        return {
            'sf': 'America/Los_Angeles',
            'nyc': 'America/New_York',
            'dc': 'America/New_York',
            'bos': 'America/New_York',
            'chi': 'America/Chicago',
            'la': 'America/Los_Angeles',
            'phx': 'America/Phoenix',
            'sea': 'America/Los_Angeles',
            'sd': 'America/Los_Angeles',
            'atl': 'America/New_York',
            'pdx': 'America/Los_Angeles',
            'oc': 'America/Los_Angeles',
            'atx': 'America/Chicago'
        }[this.props.providerServiceAreaCode]
    }

    officeAppointments() {
        return this.officeProviders.slice(0, 4).map((provider) => {
            return (
                <li className="office-providers__item" key={provider.id}>
                    <a href={provider.provider_url_path} className="providers-grid__anchor row row-xs--middle">
                        <div className="providers-grid__item-wrapper">
                            <div className="providers-grid__item-content">
                                <div
                                    className={'providers-grid__item-image -loaded'}
                                    style={{ backgroundImage: `url('${provider.provider_profile_image_url}')` }}
                                />
                            </div>
                        </div>
                        <div className="providers-grid__text-wrapper col-xs-9 col-md-3">
                            <h2 className="providers-grid__item-headline">{provider.provider_display_name}</h2>
                            <p className="providers-grid__item-info">
                                Available {moment(provider.appointment.startAt).tz(this.getTimezoneString()).calendar(null, {
                                    sameDay: '[today at] LT',
                                    nextDay: '[tomorrow at] LT',
                                    lastDay: '[yesterday]',
                                    lastWeek: '[last] dddd [at] LT'
                                })}
                            </p>
                        </div>
                    </a>
                </li>
            )
        })
    }

    providerAppointments() {
        const appointments = this.primaryProvider.appointments.slice(0, MAX_NUMBER_OF_APPOINTMENTS_TO_SHOW)
        const appointmentInventoriesByDate = this.groupBy(appointments, 'date')

        return Object.keys(appointmentInventoriesByDate).map((dateString) => {
            return (
                <Fragment key={`${dateString}-date-group`}>
                    { this.appointmentInventoryDateHeader(dateString) }
                    {
                        appointmentInventoriesByDate[dateString].map((appointmentInventory) => {
                            return this.appointmentInventoryRow(appointmentInventory)
                        })
                    }
                </Fragment>
            )
        })
    }

    providerHasAppointments() {
        return this.primaryProvider && this.primaryProvider.appointments && this.primaryProvider.appointments.length
    }

    officeHasAppointments() {
        return this.officeProviders && this.officeProviders.length
    }

    appointmentInventoryDateHeader(dateString) {
        return (
            <tr className="dateHeader" key={dateString}>
                <th className="office-appointments__table--th"><h5>{this.dateStringHeader(dateString)}</h5></th>
                <th className="office-appointments__table--th">&nbsp;</th>
                <th className="office-appointments__table--th">&nbsp;</th>
            </tr>
        )
    }

    appointmentInventoryRow(appointmentInventory) {
        return (
            <tr key={appointmentInventory['id']}>
                <td className="office-appointments__table--td">
                    {moment(appointmentInventory['startAt']).tz(this.getTimezoneString()).format('h:mm A')}
                </td>
                <td className="office-appointments__table--td">
                    <span className='appointment-duration'>{appointmentInventory['duration']} min</span>
                    <span className='appointment-label'>{appointmentInventory['label']}</span>
                </td>
                <td className="office-appointments__table--td button-td">
                    <span className="-btn-pill--outline -opacity-25">Book</span>
                </td>
            </tr>
        )
    }

    appointmentOptions() {
        if (this.providerHasAppointments()) {
            return (
                <div className="office-appointments__timeslots-wrapper">
                    <table className="office-appointments__table">
                        <tbody>
                            { this.providerAppointments() }
                        </tbody>
                    </table>
                </div>
            )
        } else if (this.officeHasAppointments()) {
            return ( <div>{ this.primaryProvider.provider_title } doesn’t have appointments for new patients in the near future, but here are some other providers from the same office:
                <div className="office-providers__wrapper">
                    <h3 className="office-providers__header">From the same office</h3>
                    <ul className="office-providers__list">
                        { this.officeAppointments() }
                    </ul>
                </div>
            </div>
            )
        }
    }

    render() {
        return (
            <div className="office-appointments">
                <div className="office-appointments__wrapper">
                    <h2 className="office-appointments__headline">Appointments</h2>
                    { this.appointmentOptions() }
                </div>
                <div className="office-appointments__callout-block">
                    <span className="office-appointments__callout-block--header">
                        { this.providerHasAppointments() ? (
                            <>Want to see this provider?</>
                        ) : (
                            <>Want to see a provider?</>
                        )}
                    </span>
                    <p className="office-appointments__callout-block--body-copy">
                        Book an appointment today by becoming a One Medical member for just $199/year.
                    </p>
                    <a href={this.signupUrl()} className="-btn-pill">Sign up to book</a>
                </div>
            </div>
        )
    }
}

export default class MountProviderAppointmentsList {
    constructor(el) {
        this.el = el
        this.fetchAppointments()
    }

    fetchAppointments(req = '/api/appointments/provider/') {
        let params = {
            office_id: this.el.dataset.defaultOfficeId, // eslint-disable-line camelcase
            cache_buster: Math.random().toString(24).substr(4, 4) // eslint-disable-line camelcase
        }

        axios.get(req.concat(this.el.dataset.providerId), {
            params: params
        })
            .then(res => {
                if (res.data) {
                    this.renderComponent(res.data)
                } else {
                    console.info('no appointments data found')
                }

            })
            .catch(err => {
                this.renderComponent([])
                console.error(err)
            })
    }

    renderComponent(providerAppointmentData) {
        this.component = render(
            <ProviderAppointmentsList
                element={this.el}
                data={providerAppointmentData}
                currentServiceAreaId={this.el.dataset.currentServiceAreaId}
                providerServiceAreaCode={this.el.dataset.providerServiceAreaCode}
            />,
            this.el
        )
    }
}

export const ProviderAppointmentsListComponent = {
    'name': 'Provider Appointments List',
    'class': CLASSES.COMPONENT,
    'Source': MountProviderAppointmentsList
}

/**
 * Display terms and conditions content for discount or trial code in URL
 * content is targeted by matching HTML data attribute "data-discount-code"
 * HTML data attribute can contain a whitespace-separated list of multiple discount or trial codes
 *
 * example given the following URL:
 * https://onemedical.com/?discount_code=SHAREBETTERCARE
 *
 * content to display:
 * <div class="-hidden" data-discount-code="SHAREBETTERCARE">
 *     <p>Terms and Conditions here...</p>
 * </div>
 *
 */
export default class LoadDiscountTerms {
    constructor() {
        this.initialize()
    }

    initialize() {
        const searchParams = new URLSearchParams(window.location.search)
        const discountCode = searchParams.get('discount_code') || searchParams.get('trial_code') || ''
        const termsElements = document.querySelectorAll(`[data-discount-code~="${discountCode}"]`) || []
        for (let element of termsElements) {
            element.classList.remove('-hidden')
        }
    }
}

import {GLOBAL_CONSTANTS} from 'utils/constants'
import Flickity from 'flickity-fade'
import 'flickity-as-nav-for'

const SELECTORS = {
    COMPONENT: '.js-carousel'
}

export default class Carousel {

    constructor() {
        this.registerFlickity()
    }

    registerFlickity() {
        const sliders = document.querySelectorAll(SELECTORS.COMPONENT)
        for (let slider of sliders) {
            let opts = {
                lazyLoad: true,
                fade: true
            }
            if (slider.classList.contains('js-testimonial-carousel')) {
                opts.autoPlay = 5000
                opts.prevNextButtons = true
                opts.wrapAround = true
                opts.arrowShape = GLOBAL_CONSTANTS.CAROUSEL_ARROW
            } else if (slider.classList.contains('js-industry-carousel')) {
                opts.autoPlay = 5000
                opts.prevNextButtons = true
                opts.wrapAround = true
                opts.fade = false
                opts.imagesLoaded = true
                opts.arrowShape = GLOBAL_CONSTANTS.CAROUSEL_ARROW
            } else if (slider.classList.contains('js-feature-carousel')) {
                opts.autoPlay = false
                opts.prevNextButtons = true
                opts.wrapAround = true
                opts.fade = false
                opts.imagesLoaded = true
                opts.pageDots = false
                opts.arrowShape = GLOBAL_CONSTANTS.CAROUSEL_ARROW
            } else {
                opts.autoPlay = true
                opts.prevNextButtons = false
            }

            this.flickity = new Flickity(slider, opts)
        }
    }
}


export const CarouselComponent = {
    'name': 'Carousel',
    'class': SELECTORS.COMPONENT,
    'Source': Carousel
}

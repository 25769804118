import * as Sentry from '@sentry/browser'

export default class SentryWrapper {

    constructor() {

        Sentry.init({
            dsn: 'https://522a93cff7d233301704c556d2677dbe@o4506221115408384.ingest.sentry.io/4506504189902848',

            environment: DEPLOYMENT_ENV

            // TODO: set release version
            // release: 'release-325',

            // The following configuration is for performance monitoring
            // which is not enabled on our plan
            //
            // integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
            //
            // // Set tracesSampleRate to 1.0 to capture 100%
            // // of transactions for performance monitoring.
            // // We recommend adjusting this value in production
            // tracesSampleRate: 1.0,
            //
            // // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            // tracePropagationTargets: ['localhost', /^https:\/\/mktg-(dev|stage)\.onemedical\.com/, /^https:\/\/www\.onemedical\.com/],
            //
            // // Capture Replay for 10% of all sessions,
            // // plus for 100% of sessions with an error
            // replaysSessionSampleRate: 0.1,
            // replaysOnErrorSampleRate: 1.0
        })
    }
}

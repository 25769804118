import { GLOBAL_CONSTANTS } from 'utils/constants'

const CLASSES = {
    COMPONENT: '.js-insurance-table-select',
    INSURANCE_TABLE: '.insurance__table'
}

export default class InsuranceTableClass {
    constructor() {
        this.form = document.querySelector(CLASSES.COMPONENT)
        this.insuranceTables = Array.from(document.querySelectorAll(CLASSES.INSURANCE_TABLE))
        this.registerEvents()
    }

    registerEvents() {
        this.form.addEventListener('change', this.submitForm.bind(this))
    }

    submitForm(e) {
        this.insuranceTables.forEach(table => {
            if (table.dataset.id === e.target.value ) {
                table.classList.remove(GLOBAL_CONSTANTS.CLASSES.HIDDEN)
            } else {
                table.classList.add(GLOBAL_CONSTANTS.CLASSES.HIDDEN)
            }
        })
    }
}

export const InsuranceTable = {
    'name': 'Insurance Table',
    'class': CLASSES.COMPONENT,
    'Source': InsuranceTableClass
}
import Emitter from 'utils/emitter'
import { GLOBAL_CONSTANTS } from 'utils/constants'

const CLASSES = {
    PROVIDER_INFO: '.provider-detail',
    BOOKING_WRAPPER: '.provider-booking',
    BUTTON_CLOSE: '.js-provider-booking-button-close',
    OVERLAY: '.fixed-black-overlay',
    OVERLAY_DEACTIVATED: '-deactivated',
    ACTIVE: '-active'
}

const OFFSET = -800

export default class ProviderBooking {
    constructor(element) {
        this.el = element
        this.windowHeight = window.innerHeight
        this.body = document.getElementsByTagName('body')
        this.overlay = document.querySelector(CLASSES.OVERLAY)
        this.cancel = document.querySelector(CLASSES.BUTTON_CLOSE)
        this.wrapper = document.querySelector(CLASSES.PROVIDER_INFO)
        this.position = (this.wrapper.offsetTop + this.wrapper.offsetHeight) - this.windowHeight - OFFSET

        this.scrollBookingShow = function() {
            if (window.pageYOffset > this.position) {
                if (this.el.classList.contains(CLASSES.OVERLAY_DEACTIVATED)) {
                    return
                }

                this.el.classList.add(CLASSES.ACTIVE)
                this.overlay.classList.add(CLASSES.ACTIVE)
            }
        }

        this.scrollBookingHandler = this.scrollBookingShow.bind(this)

        this.attachEvents()
    }

    attachEvents() {
        Emitter.on(GLOBAL_CONSTANTS.EVENTS.SCROLL, this.scrollBookingHandler)

        this.cancel.addEventListener('click', (e) => {
            e.preventDefault()
            e.stopPropagation()

            Emitter.off(GLOBAL_CONSTANTS.EVENTS.SCROLL, this.scrollBookingHandler)

            this.el.classList.remove(CLASSES.ACTIVE)
            this.overlay.classList.remove(CLASSES.ACTIVE)
        })
    }
}

export const ProviderBookingComponent = {
    'name': 'Provider Booking Popup',
    'class': CLASSES.BOOKING_WRAPPER,
    'Source': ProviderBooking
}
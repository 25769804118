import { GLOBAL_CONSTANTS } from 'utils/constants'

const CLASSES = {
    COMPONENT: '.js-lazy-load',
    IMAGE_HERO: '.js-lazy-load-bg'
}

export default class LazyLoad {
    /**
     * @desc Set up lazy loading and bind events.
     * @param {HTMLElement} el - Element that contains possible sub-navigations
     *
     */
    constructor(element) {
        this.element = element
        this.images = Array.from(document.body.querySelectorAll(CLASSES.COMPONENT))
        this.bgEls = Array.from(document.body.querySelectorAll(CLASSES.IMAGE_HERO))
        this.srcSets = Array.from(document.body.querySelectorAll('[data-srcset]'))
        this.observer = this.registerObserver()
        this.loadImages()
    }

    /**
     * @desc Creates an observer in which we can register our elements against
     * @param {HTMLElement} el - Image element
     */
    registerObserver() {
        const options = {
            root: null,
            rootMargin: `${window.innerHeight}px`,
            threshold: 0.0
        }

        return new IntersectionObserver(this.imageInView.bind(this), options)
    }

    /**
     * @desc Callback for intersectionObserver that says the image is in the viewport
     * and is ready to be displayed.
     * @param {HTMLElement} entries - List of image entries from the observer
     */
    imageInView(entries) {
        entries.forEach(entry => {
            if (entry.intersectionRatio > 0) {
                this.loadImage(entry.target)
            }
        })
    }

    /**
     * @desc Loops over all images with the lazy load class and
     * bg lazy load class and runs function to check if it's in viewport
     */
    loadImages() {
        const imageList = [...this.images, ...this.bgEls, ...this.srcSets]
        imageList.forEach((image) => {
            this.observer.observe(image)
        })
    }

    /**
     * @desc If the image is in the viewport, set it's source appropriately
     * @param {HTMLElement} img - The image that is in the viewport
     */
    loadImage(img) {
        if (img.hasAttribute('data-src')) {
            const src = img.getAttribute('data-src')

            img.onload = this.imageLoaded(img)
            img.removeAttribute('data-src')
            img.src = src

        } else if (img.hasAttribute('data-bg')) {
            const src = img.getAttribute('data-bg')

            img.onload = this.imageLoaded(img)
            img.style.backgroundImage = `url(${src})`
            img.removeAttribute('data-bg')
        } else if (img.hasAttribute('data-srcset')) {
            const src = img.getAttribute('data-srcset')

            img.srcset = src
            img.removeAttribute('data-srcset')
        }
    }

    /**
     * @desc Display the image by adding a class that toggles opacity
     * The timeout is required here for the animation to work correctly.
     * @param {HTMLElement} img - The image that is in the viewport
     */
    imageLoaded(img) {
        this.observer.unobserve(img)
        setTimeout(() => {
            img.classList.add(GLOBAL_CONSTANTS.CLASSES.LOADED)
        }, GLOBAL_CONSTANTS.TIMING.FAST_ANIM_TIME)
    }
}

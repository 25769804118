import Cookies from 'js-cookie'

/**
 * Functionality to set cookies based on utm params
 */
export default class SetUtmCookies {
    /**
     * @desc Set up utm cookies
     */
    constructor() {
        this.initialize()
    }

    /**
     * @desc initialize the class functions after global variables are defined
     */
    initialize() {
        this.setCookies()
    }

    /**
     * @desc sets the cookies
     */
    setCookies() {
        let searchParams = new URL(window.location.href).searchParams

        // set cookie options
        let inThirtyMinutes = new Date(new Date().getTime() + 1 * 60 * 1000)
        let cookieOptions = {
            expires: inThirtyMinutes
        }

        // set each cookie
        let utmSourceParam = searchParams.get('utm_source')
        if (utmSourceParam) {
            Cookies.set('utm_source', utmSourceParam, cookieOptions)
        }
        let utmMediumParam = searchParams.get('utm_medium')
        if (utmMediumParam) {
            Cookies.set('utm_medium', utmMediumParam, cookieOptions)
        }
        let utmContentParam = searchParams.get('utm_content')
        if (utmContentParam) {
            Cookies.set('utm_content', utmContentParam, cookieOptions)
        }
        let utmCampaignParam = searchParams.get('utm_campaign')
        if (utmCampaignParam) {
            Cookies.set('utm_campaign', utmCampaignParam, cookieOptions)
        }
        let utmTermParam = searchParams.get('utm_term')
        if (utmTermParam) {
            Cookies.set('utm_term', utmTermParam, cookieOptions)
        }
    }
}

import Emitter from 'utils/emitter'
import { throttle } from 'throttle-debounce'
import { GLOBAL_CONSTANTS, isStandardTablet} from 'utils/constants'
import { preventScroll, allowScroll } from 'utils/scrollUtil'
/**
 * Makes the nav show / hide based on
 * scroll position / direction.
 */

const CLASSES = {
    COMPONENT: '.js-navigation',
    NAV_LINK: '.js-nav-menu-link',
    NAV_MENU: '.js-nav-menu',
    NAV_ACTIVE: 'nav-active',
    TOP_ZERO: 'top-zero',
    MOBILE_NAV_ITEM: 'js-mobile-item',
    LOCATION_MENU_BUTTON: '.js-location-menu-btn-footer',
    LOCATION_DRAWER_CLOSE: '.footer__drawer-close',
    LOCATION_DRAWER_ACTIVE: 'location-drawer-active',
    LOCATION_DRAWER: '.js-location-drawer',
    LOCATION_ITEM: '.js-location-item',
    HEADER_ITEM: '.js-header-item',
    SEARCH: '.js-search',
    LOGO: '.js-header-logo',
    LOCATIONS_FORM: '#locations-form',
    NAVIGATION_POSITION_ABSOLUTE: 'navigation--position-absolute',
    EMAIL_CAPTURE: 'navigation-email-capture'
}

export default class Nav {
    /**
     * @desc Set up nav with elements and bind events.
     * @param {HTMLElement} el - Element that contains possible sub-navigations
     *
     */

    constructor(element) {
        this.el = element
        this.throttleScroll = null
        this.scrollPosition = 0
        this.navLink = this.el.querySelector(CLASSES.NAV_LINK)
        this.navMenu = this.el.querySelector(CLASSES.NAV_MENU)
        this.locationDrawer = document.querySelector(CLASSES.LOCATION_DRAWER)
        this.locationDrawerToggle = document.querySelector(CLASSES.LOCATION_MENU_BUTTON)
        this.locationDrawerClose = document.querySelector(CLASSES.LOCATION_DRAWER_CLOSE)
        this.navItems = Array.from(this.navMenu.getElementsByTagName('A'))
        this.locationItems = Array.from(this.el.querySelectorAll(CLASSES.LOCATION_ITEM))
        this.headerItems = Array.from(this.el.querySelectorAll(CLASSES.HEADER_ITEM))
        this.search = this.el.querySelector(CLASSES.SEARCH)
        this.logo = this.el.querySelector(CLASSES.LOGO)
        this.locationsForm = this.el.querySelector(CLASSES.LOCATIONS_FORM)
        this.topNavBanner = document.querySelector('.js-top-nav-banner')
        this.handleLocationsKeyDown = this.handleLocationsKeyDown.bind(this)
        this.handleMenuKeyDown = this.handleMenuKeyDown.bind(this)
        this.toggleLocationDrawer = this.toggleLocationDrawer.bind(this)
        this.handleMobileMenuClick = this.handleMobileMenuClick.bind(this)
        this.handleScroll()
        this.initialize()
    }

    /**
     * @desc initialize the class functions after global variables are defined
     */
    initialize() {
        this.registerEvents()
    }


    /**
     * @desc Listen for the global scroll event.
     */
    registerEvents () {
        this.throttleScroll = throttle(GLOBAL_CONSTANTS.TIMING.NAV_SCROLL_THROTTLE, this.handleScroll.bind(this))
        Emitter.on(GLOBAL_CONSTANTS.EVENTS.SCROLL, this.throttleScroll)

        this.navLink.addEventListener('click', this.handleMobileMenuClick)
        if (this.locationDrawerToggle) {
            this.locationDrawerToggle.addEventListener('click', this.toggleLocationDrawer)
        }

        this.locationDrawerClose.addEventListener('click', this.toggleLocationDrawer)
    }

    /**
     * @desc Handle key down for menu to set proper focus
     */
    handleMenuKeyDown (e) {
        let visible = this.navItems
        if ( !isStandardTablet() ) {
            visible = this.navItems.filter(item => {
                return !item.parentNode.classList.contains(CLASSES.MOBILE_NAV_ITEM)
            })
        }

        const keyCode = e.keyCode || e.which
        if (document.body.classList.contains(CLASSES.NAV_ACTIVE) && keyCode === 27) { // Handles escape key
            this.handleMobileMenuClosed()
            this.navLink.focus()
        } else if (e.target === visible[visible.length - 1] && !e.shiftKey && keyCode === 9) { // Handles tabbing past the last menu item to return to menu toggle button
            if ( !isStandardTablet() ) {
                this.search.focus()
            } else {
                this.logo.focus()
            }
        }
    }

    /**
     * @desc Handle key down for location drawer to set proper focus
     */
    handleLocationsKeyDown (e) {
        const keyCode = e.keyCode || e.which
        if (document.body.classList.contains(CLASSES.LOCATION_DRAWER_ACTIVE) && keyCode === 27) { // Handles escape key
            this.handleLocationDrawerClose()
            this.locationDrawerToggle.focus()
        } else if (e.target === this.locationItems[this.locationItems.length - 1] && !e.shiftKey && keyCode === 9) { // Handles tabbing past the last menu item to return to menu toggle button
            this.logo.focus()
        } else if (this.locationItems.includes(e.target) && keyCode === 13) { // Handles enter key press to check item and submit form
            e.target.checked = true
            this.locationsForm.submit()
        }
    }


    /**
     * @desc Handles orientation change by firing our handleScroll function
     */
    handleOrientationChange () {
        this.handleScroll(true)
    }

    /**
     * @desc Scroll event that determines if the nav should show or hide.
     */
    handleScroll (hasOrientationChanged) {
        if (hasOrientationChanged) {
            this.setActive(false)
            return
        }

        const pos = window.pageYOffset
        const originalNavThreshold = 100
        let activateNavThreshold = originalNavThreshold

        if (this.topNavBanner) {
            activateNavThreshold = originalNavThreshold + this.topNavBanner.offsetHeight
            if (pos > this.topNavBanner.offsetHeight) {
                this.el.classList.remove(CLASSES.NAVIGATION_POSITION_ABSOLUTE)
                this.el.classList.add(CLASSES.TOP_ZERO)
            } else {
                this.el.classList.add(CLASSES.NAVIGATION_POSITION_ABSOLUTE)
                this.el.classList.remove(CLASSES.TOP_ZERO)
            }

        }

        if (pos < activateNavThreshold) {
            this.el.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        } else {
            this.el.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        }

    }

    toggleLocationDrawer(e) {
        if (e) {
            e.preventDefault()
        }

        document.body.classList.toggle(CLASSES.LOCATION_DRAWER_ACTIVE)
        const footer = document.body.querySelector('.footer')

        if (document.body.classList.contains(CLASSES.LOCATION_DRAWER_ACTIVE)) {
            this.locationDrawer.setAttribute('aria-hidden', false)
            this.locationDrawerToggle.setAttribute('aria-expanded', true)
            // Get drawer height and footer height.
            const drawerWrapper = document.body.querySelector('.navigation__location-wrapper')
            const drawerHeight = drawerWrapper.offsetHeight
            const footerHeight = footer.offsetHeight
            // Check if drawer height is greater than footer height.
            if (drawerHeight > footerHeight) {
                // Wait for animation.
                setTimeout(function() {
                    // Set footer height to accommodate drawer height.
                    footer.style.height = drawerHeight + 120 + 'px' // Add 120px to offset drawer top padding of 120px (set in footer.scss)
                }, 500) // Set timeout duration to be equal to transition duration set in footer.scss (currently .5s)
            }

        } else {
            this.locationDrawer.setAttribute('aria-hidden', true)
            this.locationDrawerToggle.setAttribute('aria-expanded', false)
            footer.style.height = 'auto'
        }
    }

    handleMobileMenuClick (e) {
        e.preventDefault()
        if (document.body.classList.contains(CLASSES.NAV_ACTIVE)) {
            this.handleMobileMenuClosed()
            document.body.removeEventListener('keydown', this.handleMenuKeyDown)
        } else {
            if (document.body.classList.contains(CLASSES.LOCATION_DRAWER_ACTIVE)) {
                this.toggleLocationDrawer()
            }

            document.body.addEventListener('keydown', this.handleMenuKeyDown)

            this.navLink.setAttribute('aria-expanded', true)
            this.navLink.setAttribute('aria-label', 'Close Menu')
            this.navMenu.setAttribute('aria-hidden', false)

            document.body.classList.add(CLASSES.NAV_ACTIVE)
            this.scrollPosition = window.pageYOffset

            setTimeout(function() {
                document.body.classList.contains(CLASSES.NAV_ACTIVE) && preventScroll()
            }, 800)

            this.navItems.forEach(item => {
                item.setAttribute('tabindex', '0')
            })
        }
    }

    handleLocationDrawerClose () {
        this.locationDrawerToggle.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        document.body.classList.remove(CLASSES.LOCATION_DRAWER_ACTIVE)
        this.el.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
        this.locationDrawerToggle.setAttribute('aria-expanded', false)
        this.locationDrawer.setAttribute('aria-hidden', true)
        this.headerItems.forEach(item => {
            item.setAttribute('tabindex', '0')
        })
        this.locationItems.forEach(item => {
            item.setAttribute('tabindex', '-1')
        })
    }

    handleMobileMenuClosed () {
        document.body.classList.remove(CLASSES.NAV_ACTIVE)
        this.navLink.setAttribute('aria-expanded', false)
        this.navLink.setAttribute('aria-label', 'Open Menu')
        this.navMenu.setAttribute('aria-hidden', true)

        this.navItems.forEach(item => {
            item.setAttribute('tabindex', '-1')
        })
        allowScroll(this.scrollPosition)
    }

    /**
     * @desc Tear down the event listeners
     */
    tearDown () {
        Emitter.off(GLOBAL_CONSTANTS.EVENTS.SCROLL, this.handleScroll)
    }
}

/**
 * @desc Test component definition used in module-loader
 */

export const NavComponent = {
    'name': 'Nav',
    'class': CLASSES.COMPONENT,
    'Source': Nav
}
